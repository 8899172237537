import React, { FunctionComponent } from "react";
import { ModalProps } from "antd/lib/modal/Modal";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const AppLoaderWrapper = styled("div")``;

interface AppLoaderProps extends ModalProps {}

/**
 * Application Modal
 */
const AppLoader: FunctionComponent<AppLoaderProps> = () => {
  return (
    <Spin
      style={{ color: "#203C72" }}
      indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
    />
  );
};

AppLoader.propTypes = {};

AppLoader.defaultProps = {};

export default AppLoader;