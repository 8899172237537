import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CardButton } from "./CardButton";
import Checkbox from "./../Checkbox";
import CMBAmount from "./CMBAmount";
import TierPercentage from "./TierPercentage";
import CardImage from "./CardImage";
import CardTag from "./CardTag";
import IconWIthLabel from "./IconWIthLabel";

const MonkCardWrapper = styled.div<{ redCheckbox: boolean; disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.1 : 1)};
  cursor: "pointer";
  .main-widget {
    width: 214px;
    height: 292px;
  }
  .main-widget-checked {
    border: 3px solid ${(props) => (props.redCheckbox ? "#FF416F" : "#00e38c")};
  }
  .widget-label {
    position: absolute;
    left: 10px;
    top: 8px;
  }
  .widget-checkbox {
    position: absolute;
    right: 11px;
    top: 8px;
  }
  @media (max-width: 991.98px) {
    .main-widget {
      width: 160px;
      height: 220px;
      margin-right: 10px;
    }
    .widget-section {
      padding: 5px 6px 7px 6px;
    }
    .widget-section-checked {
      padding: 2px 3px 3px 3px;
    }
    .widget-label {
      left: 6px;
      top: 5px;
    }
    .widget-checkbox {
      right: 11px;
      top: 4px;
    }
    .widgetHeading {
      font-size: 14px;
      padding-top: 6px;
    }
    .widget-price-button {
      padding-top: 6px;
    }
  }
`;

interface MonkCardProps {
  cmbAmount?: number;
  tier?: {
    tierPercentage: number;
    tierValue: number;
  };
  src: string;
  cardName: string;
  cardId?: string;
  tagType?: string;
  tagId?: number;
  iconWithLabel?: {
    id: string;
    icon: string;
    label: string;
    isShow: boolean;
  } | null;
  cardActionButton?: {
    type: string;
    onClick: () => void;
    isShow: boolean;
  };
  checkBoxType?: string | null;
  disabled?: boolean;
  isChecked?: boolean;
  onSelectChange?: (checked: boolean) => void;
}

const MonkCard: FunctionComponent<MonkCardProps> = ({
  cmbAmount = null,
  tier = null,
  src = "",
  cardName = "Monke",
  cardId = null,
  tagType = "",
  tagId = null,
  iconWithLabel = null,
  cardActionButton = null,
  checkBoxType = null,
  isChecked = false,
  onSelectChange,
  disabled = false,
}) => {
  const selectChangeHandler = () => {
    onSelectChange && onSelectChange(isChecked);
  };
  return (
    <MonkCardWrapper
      redCheckbox={checkBoxType === "secondary"}
      disabled={disabled}
    >
      <div
        className={`${
          isChecked ? "main-widget-checked" : ""
        } main-widget secondary br8 mr20`}
      >
        <div
          className={`${
            isChecked
              ? "pl8 pr9 pt7 pb11 widget-section-checked"
              : "px11 pt10 pb12 widget-section"
          } `}
          onClick={selectChangeHandler}
        >
          <div className={`widget-img relative`}>
            <div className={`${isChecked ? "widget-label" : "widget-label"}`}>
              <CardTag
                type={tagType}
                tagId={tagId}
              />
            </div>

            <div className="widget-checkbox">
              <Checkbox
                isChecked={isChecked}
                greenCheckbox={checkBoxType === "primary"}
              />
            </div>

            <CardImage src={src} />
          </div>
          <div className="widgetHeading white--text font-16 pt11 extra-bold-text">
            {cardName}: #{cardId}
          </div>
          <div className="widget-price-button white--text pt11 flex justify-between">
            {cmbAmount !== null && cmbAmount >= 0 && (
              <CMBAmount
                amount={cmbAmount}
                redCheckbox={checkBoxType === "secondary"}
              />
            )}
            {tier && (
              <TierPercentage
                tierPercentage={tier.tierPercentage}
                tierValue={tier.tierValue}
              />
            )}
            {iconWithLabel && iconWithLabel.isShow && (
              <IconWIthLabel
                labelId={iconWithLabel.id}
                labelIcon={iconWithLabel.icon}
                labelName={iconWithLabel.label}
              />
            )}
            {cardActionButton && cardActionButton.isShow && (
              <CardButton
                action={cardActionButton.type}
                onClick={cardActionButton.onClick}
              />
            )}
            {checkBoxType === "secondary" && (
              <div className="self-center" onClick={selectChangeHandler}>
                <Checkbox
                  isChecked={isChecked}
                  redCheckbox={checkBoxType === "secondary"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </MonkCardWrapper>
  );
};

MonkCard.propTypes = {};

MonkCard.defaultProps = {};

export default MonkCard;
