import React, { FunctionComponent, useState, useMemo } from "react";
import styled from "styled-components";
import StakeBalanceComponent from "components/staking/StakeBalanceComponent";
import { useSelector } from "react-redux";
import {
  getMonkeBankValue,
  getLoading,
  getStaked,
  getUnstakedMonkes,
  getUnstakedBoost,
} from "redux/selectors/monkeBank";
import AppLoader from "components/common/AppLoader";
import { MONKE_URL, BOOST_URL } from "utils/contstants";
import MonkCard from "components/common/MonkCard";
import { useNavigate } from "react-router-dom";

const StakingWrapper = styled.div``;

interface StakingProps {}

const Staking: FunctionComponent<StakingProps> = () => {
  const [actionToggle, setActionToggle] = useState("staking");
  const [selectedMonk, setSelectedMonk] = useState<any>([]);
  const [selectedBooster, setSelectedBooster] = useState<any>([]);
  const stakedValue = useSelector(getStaked);
  const Yieldbalance = useSelector(getMonkeBankValue);
  const loading = useSelector(getLoading);
  const unstakedMonkes = useSelector(getUnstakedMonkes);
  const unstakedBoost = useSelector(getUnstakedBoost);
  const navigate = useNavigate();

  const activeBooster = useMemo(() => {
    if (stakedValue?.active_boosters) {
      const data: Record<string, string> = stakedValue.active_boosters;
      const newData = Object.keys(data).reduce(function (
        obj: Record<string, string>,
        key: string
      ) {
        obj[data[key]] = key;
        return obj;
      },
      {});
      return newData;
    }
    return {};
  }, [stakedValue.active_boosters]);

  const monkedCardData = useMemo(() => {
    const monkesCard: any = [];
    if (actionToggle === "Unstake" || actionToggle === "staking") {
      if (stakedValue?.monkes) {
        Object.keys(stakedValue?.monkes)?.forEach((key: any) => {
          monkesCard.push({
            id: key,
            cmb: stakedValue?.monkes[key],
            img: `${MONKE_URL}${key}.png`,
            booster: stakedValue?.active_boosters[key],
            boosterImg: `${BOOST_URL}${stakedValue?.active_boosters[key]}`,
          });
        });
      }
    }
    return monkesCard;
  }, [stakedValue, actionToggle]);

  const boostCardData = useMemo(() => {
    const boostsCard: Array<any> = [];
    if (actionToggle === "Unstake" || actionToggle === "staking") {
      if (stakedValue?.boosts) {
        Object.keys(stakedValue?.boosts)?.forEach((key: any) => {
          boostsCard.push({
            id: key,
            tier_rate: stakedValue?.boosts[key],
            img: `${BOOST_URL}${key}`,
            attached_monk:
              activeBooster.hasOwnProperty(key) && activeBooster[key],
            boosts_to_upgrade: { id: key },
          });
        });
      }
    }
    return boostsCard;
  }, [stakedValue, actionToggle]);

  const unstakedMonkesData = useMemo(() => {
    const unstakedMonkesCard: Array<Object> = [];
    if (actionToggle === "Stake" || actionToggle === "staking") {
      if (unstakedMonkes) {
        Object.keys(unstakedMonkes)?.forEach((key: string) => {
          unstakedMonkesCard.push({
            id: key,
            cmb: unstakedMonkes[key],
            img: `${MONKE_URL}${key}.png`,
          });
        });
      }
    }
    return unstakedMonkesCard;
  }, [unstakedMonkes, actionToggle]);

  const unstakedBoostData = useMemo(() => {
    const unstakedBoostCard: Array<Object> = [];
    if (actionToggle === "Stake" || actionToggle === "staking") {
      if (unstakedBoost) {
        Object.keys(unstakedBoost)?.forEach((key: string) => {
          unstakedBoostCard.push({
            id: key,
            tier_rate: unstakedBoost[key],
            img: `${BOOST_URL}${key}`,
          });
        });
      }
    }
    return unstakedBoostCard;
  }, [unstakedBoost, actionToggle]);

  const StakeData = {
    headerType: "Stake",
    title: "Choose cards and press stake",
    description:
      "After your transaction is complete, you will get a notification in your crypto wallet.",
  };
  const UnStakeData = {
    headerType: "Unstake",
    title: "Choose cards and press unstake",
    description:
      "After your transaction is complete, you will get a notification in your crypto wallet.",
  };

  const StakingToggleData = useMemo(() => {
    if (actionToggle === "Stake") {
      return StakeData;
    } else if (actionToggle === "Unstake") {
      return UnStakeData;
    }
    return {};
  }, [actionToggle]);

  const stakedBalance = monkedCardData?.length + boostCardData?.length || "";
  const unstakedBalance =
    unstakedMonkesData?.length + unstakedBoostData?.length || "";

  const selectChangeHandler = (type: string, data: any) => {
    if (type === "monk") {
      const selectedRepeat = selectedMonk.find(
        (monk: any) => monk.id === data.id
      );
      if (data.id !== selectedRepeat?.id) {
        setSelectedMonk([...selectedMonk, data]);
      } else {
        setSelectedMonk(
          selectedMonk.filter((monk: any) => {
            return monk.id !== data.id;
          })
        );
      }
    } else if (type === "booster") {
      const selectedRepeat = selectedBooster.find(
        (monk: any) => monk.id === data.id
      );
      if (data.id !== selectedRepeat?.id) {
        setSelectedBooster([...selectedBooster, data]);
      } else {
        setSelectedBooster(
          selectedBooster.filter((monk: any) => {
            return monk.id !== data.id;
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setSelectedMonk([]);
    setSelectedBooster([]);
  };

  if (loading) {
    return <AppLoader />;
  }

  return (
    <StakingWrapper>
      <div className="ma20">
        <div>
          <StakeBalanceComponent
            stackData={StakingToggleData}
            setActionToggle={setActionToggle}
            Yieldbalance={Yieldbalance}
            actionToggle={actionToggle}
            stakedBalance={stakedBalance}
            unstakedBalance={unstakedBalance}
            data={{ selectedMonk, selectedBooster }}
            onCancel={onCancelHandler}
          />
        </div>
      </div>
      <div className="ml20 my20">
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Monkes</div>
          <div className="flex sider pb20">
            {monkedCardData?.length > 0 &&
              monkedCardData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Monk"
                  cardId={card.id}
                  tagType="stake"
                  cardActionButton={{
                    type: "boost",
                    onClick: () => navigate("/dashboard/apply%20boosters"),
                    isShow: !card.booster,
                  }}
                  cmbAmount={card.cmb}
                  isChecked={selectedMonk?.some(
                    (monk: any) => monk.id === card.id
                  )}
                  onSelectChange={() =>
                    actionToggle !== "staking"
                      ? selectChangeHandler("monk", card)
                      : ""
                  }
                  iconWithLabel={{
                    id: card.booster,
                    icon: card?.boosterImg,
                    label: "Booster",
                    isShow: card?.booster,
                  }}
                  checkBoxType={actionToggle === "staking" ? null : "primary"}
                />
              ))}
            {unstakedMonkesData?.length > 0 &&
              unstakedMonkesData?.map((card: any) => (
                <MonkCard
                  src={card.img}
                  cardName="Monke"
                  cardId={card.id}
                  tagType="unstake"
                  cmbAmount={card.cmb}
                  cardActionButton={{
                    type: "boost",
                    onClick: () => navigate("/dashboard/apply%20boosters"),
                    isShow: true,
                  }}
                  isChecked={selectedMonk?.some(
                    (monk: any) => monk.id === card.id
                  )}
                  onSelectChange={() =>
                    actionToggle !== "staking"
                      ? selectChangeHandler("monk", card)
                      : ""
                  }
                  checkBoxType={actionToggle === "staking" ? null : "primary"}
                />
              ))}
          </div>
        </div>
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Boost Passes</div>
          <div className="flex sider pb20">
            {boostCardData?.length > 0 &&
              boostCardData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Booster"
                  tagType={card.attached_monk ? "monk" : "stake"}
                  tagId={card.attached_monk ? card.attached_monk : null}
                  cardId={card.id}
                  cardActionButton={{
                    type: "upgrade",
                    onClick: () => navigate("/dashboard/upgrade%20boosters"),
                    isShow: true,
                  }}
                  tier={{
                    tierPercentage: card.tier_rate,
                    tierValue: 1,
                  }}
                  isChecked={selectedBooster?.some(
                    (monk: any) => monk.id === card.id
                  )}
                  onSelectChange={() =>
                    actionToggle !== "staking"
                      ? selectChangeHandler("booster", card)
                      : ""
                  }
                  checkBoxType={actionToggle === "staking" ? null : "primary"}
                />
              ))}
            {unstakedBoostData?.length > 0 &&
              unstakedBoostData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Booster"
                  cardId={card.id}
                  tagType="unstake"
                  cardActionButton={{
                    type: "upgrade",
                    onClick: () => navigate("/dashboard/upgrade%20boosters"),
                    isShow: true,
                  }}
                  tier={{
                    tierPercentage: card.tier_rate,
                    tierValue: 1,
                  }}
                  isChecked={selectedBooster?.some(
                    (monk: any) => monk.id === card.id
                  )}
                  onSelectChange={() =>
                    actionToggle !== "staking"
                      ? selectChangeHandler("booster", card)
                      : ""
                  }
                  checkBoxType={actionToggle === "staking" ? null : "primary"}
                />
              ))}
          </div>
        </div>
      </div>
    </StakingWrapper>
  );
};

Staking.propTypes = {};

Staking.defaultProps = {};

export default Staking;
