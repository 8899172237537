import React, { FunctionComponent } from "react";
import styled from "styled-components";


const CardImageWrapper = styled.div`
 @media (max-width: 991.98px) {
    .widget-image {
      width: 148px !important;
      height: 148px !important;
    }
  }
`;

interface CardImageProps {
  src: string
}

const CardImage: FunctionComponent<CardImageProps> = ({ src }) => {
  return (
      <CardImageWrapper>
        <img
          src={src}
          alt="MonkCardImg"
          style={{ width: "192px", height: "192px" }}
          className="widget-image"
        />
      </CardImageWrapper>
  )
};

CardImage.propTypes = {
  
};

CardImage.defaultProps = {
 
};

export default CardImage;
