export const MONKE_URL = "https://coolmonkes.s3.amazonaws.com/";
export const BOOST_URL = "http://coolmonkes.io/api/boosts/";
export const TRAINER_URL = "http://coolmonkes.io/api/trainers/";
export const TrainerClaimed_URL = "http://coolmonkes.io/api/istrainerclaimed/";

export const cardOfHeader = (id: string, stakedValue: any) => {
  let activeBoosterValue = stakedValue?.active_boosters
    ? Object.values(stakedValue?.active_boosters)
    : [];

  let activeBoosterKey = stakedValue?.active_boosters
    ? Object.keys(stakedValue?.active_boosters)
    : [];

  const index = activeBoosterValue?.indexOf(Number(id));
  let activeMonkeKey = null;
  if (index >= 0) {
    activeMonkeKey = activeBoosterKey[index];
  }
  if (activeMonkeKey) {
    return `Monke: #${activeMonkeKey}`;
  }
  return "Staked";
};
