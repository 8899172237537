import React, { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "containers/MainLayout";
import OnePage from "containers/landing/OnePage";
import ProtectedRoute from "./ProtectedRoute";

interface AppRoutesProps {}

const AppRoutes: FunctionComponent<AppRoutesProps> = () => {

  return (
    <Routes>
      <Route path="/dashboard/*" element={<ProtectedRoute Component={MainLayout} />} />
      <Route path="/" element={<OnePage />} />
    </Routes>
  );
};

export default AppRoutes;
