import React, { FunctionComponent, useMemo } from "react";
import styled from "styled-components";
import { staked_icon, unstaked_icon } from "helper/constants";

const CardTagWrapper = styled.div<{ background: string }>`
  .label-background {
    background: ${(props) => props.background};
  }
  .icon {
    vertical-align: sub;
  }
`;

enum TagType {
  stake = "stake",
  unstake = "unstake",
  available = "available",
  monk = "monk"
}

interface CardTagProps {
  type: TagType | Object;
  tagId?: number | null
}

const CardTag: FunctionComponent<CardTagProps> = ({
  type = "unstake",
  tagId = null
}) => {

  const tagData = useMemo(() => {
    if(type === TagType.stake) {
      return {
        label: "Staked",
        icon: staked_icon,
        backColor: "#00E38C"
      }
    } else if(type === TagType.available) {
      return {
        label: "Available",
        icon: unstaked_icon,
        backColor: "#343A55"
      }
    } else if(type === TagType.monk) {
      return {
        label: "Monke",
        icon: staked_icon,
        backColor: "#00E38C"
      }
    } else {
      return {
        label: "Unstaked",
        icon: unstaked_icon,
        backColor: "#343A55"
      }
    }
  }, [type])

  return (
    <CardTagWrapper background={tagData.backColor}>
      <div className="flex py3 label-background br5">
        <div className="px6">
          <img src={tagData.icon} alt="staked_icon" className="icon" />
        </div>
        <div
          className={`font-12 pr6 self-center pt2 ${
            type === TagType.available || type === TagType.unstake ? "lightgray--text" : "secondary--text"
          }`}
        >
          {tagData.label}{tagId && `: #${tagId}`}
        </div>
      </div>
    </CardTagWrapper>
  );
};

CardTag.propTypes = {};

CardTag.defaultProps = {};

export default CardTag;
