import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { Button } from "components/common/Button";
import {
  confirmation_animation_emoji,
  price_coin,
  stake_abi,
  stake_contract
  // true_arrow,
} from "helper/constants";
import { stackDatainterface } from "interface/monkeBank";
import MonkModal from "components/common/Modal";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { getURLToken } from "redux/selectors/auth";
import { useSelector } from "react-redux";
import MonkeBankAPI from "services/monkeBank.service";

const StakeBalanceComponentWrapper = styled.div`
  @media (max-width: 575.98px) {
    .stake-section {
      flex-direction: column;
      padding: 15px;
    }
    .price-sec {
      padding-right: 20px;
    }
    .btn-sec {
      padding: 0px 20px;
    }
    .btn-sec2 {
      padding-left: 20px;
    }
    .font-24 {
      font-size: 20px;
    }
    .staking-btn {
      margin-top: 24px;
    }
    .btn-style {
      padding: 7px 54px;
    }
  }
`;

interface StakeBalanceComponentProps {
  stackData?: stackDatainterface;
  setActionToggle?: any;
  Yieldbalance?: any;
  actionToggle?: string;
  stakedBalance?: number | string;
  unstakedBalance?: number | string;
  data?: {
    selectedMonk: Array<any>,
    selectedBooster: Array<any>
  };
  onCancel? : () => void
}

const StakeBalanceComponent: FunctionComponent<StakeBalanceComponentProps> = ({
  stackData: { headerType = "", title = "", description = "" } = {},
  setActionToggle,
  Yieldbalance,
  actionToggle,
  stakedBalance,
  unstakedBalance,
  data,
  onCancel
}) => {
  const toggleStake = headerType === "Stake" ? "Unstake" : "Stake";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const reduxStoreToken = useSelector(getURLToken);
  const monkeBankAPI = new MonkeBankAPI();

  const onCloseModal = () => {
    setIsModalVisible(false);
  }
  
  const unstakeHandler = () => {
    setActionToggle && setActionToggle("Unstake");
  };

  const getWalletSigner = async () => {
    const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];

    const providerOptions = {
      /* See Provider Options Section */
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
    return signer;
  };

  const stakeItems = async () => {
    const signer = await getWalletSigner();
    let  monkesToStake: any[] = [];
    let  boostersToStake: any[] = [];
    
    data?.selectedMonk.forEach(function (item, index) {
      monkesToStake.push(parseInt(item.ids));
    });

    data?.selectedBooster.forEach(function (item, index) {
      boostersToStake.push(parseInt(item.id));
    });
    
    var response = await monkeBankAPI.getStakeValidate(reduxStoreToken, monkesToStake.length + boostersToStake.length);
    var parsedResponse = JSON.parse(response.data);
    const erc20_rw = new ethers.Contract(stake_contract, stake_abi, signer);

    const gasAmount = await erc20_rw.estimateGas.stake(reduxStoreToken, monkesToStake, boostersToStake, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"]);

    const register = await erc20_rw.stake(reduxStoreToken, monkesToStake, boostersToStake, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"], {
      gasLimit: gasAmount
    });

    const transactionHash = register["hash"];

    console.log(register);
  };

  const unstakeItems = async () => {
    const signer = await getWalletSigner();
    let  monkesToUnstake: any[] = [];
    let  boostersToUnstake: any[] = [];
    
    data?.selectedMonk.forEach(function (item, index) {
      monkesToUnstake.push(parseInt(item.ids));
    });

    data?.selectedBooster.forEach(function (item, index) {
      boostersToUnstake.push(parseInt(item.id));
    });

    var response = await monkeBankAPI.getStakeValidate(reduxStoreToken, monkesToUnstake.length + boostersToUnstake.length);
    var parsedResponse = JSON.parse(response.data);
    const erc20_rw = new ethers.Contract(stake_contract, stake_abi, signer);

    const gasAmount = await erc20_rw.estimateGas.unstake(reduxStoreToken, monkesToUnstake, boostersToUnstake, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"]);

    const register = await erc20_rw.unstake(reduxStoreToken, monkesToUnstake, boostersToUnstake, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"], {
      gasLimit: gasAmount
    });

    const transactionHash = register["hash"];
  };
  
  const onClickHandler = () => {
    if(headerType === "Stake"){
      console.log("StakedData >>>");
      stakeItems();
    }else if(headerType === "Unstake"){
      console.log("UnstakedData >>>");
      unstakeItems();
    }
  }

  const onCancelHandler = () => {
    setActionToggle && setActionToggle("staking");
    onCancel && onCancel();
  }

  return (
    <StakeBalanceComponentWrapper>
      <div className="secondary br4">
        <div className="flex justify-between py15 px20 stake-section">
          {title !== "" || description !== "" ? (
            <div>
              <div className="font-16 extra-bold-text pb5">{title}</div>
              <div className="font-14 regular-text lightgray--text">
                {description}
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="pr30 border-right price-sec">
                <div className="font-12 regular-text lightgray--text">
                  Yield per Day, $CMB:
                </div>
                <div className="font-24 extra-bold-text">
                  {Yieldbalance?.yield} {""}
                  <img src={price_coin} alt="coin" />
                </div>
              </div>
              <div className="px30 border-right btn-sec">
                <div className="font-12 regular-text lightgray--text">
                  Staked:
                </div>
                <div className="font-24 extra-bold-text">
                  {stakedBalance}
                </div>
              </div>
              <div className="pl30 btn-sec2">
                <div className="font-12 regular-text lightgray--text">
                  Unstaked:
                </div>
                <div className="font-24 extra-bold-text">
                  {unstakedBalance}
                </div>
              </div>
            </div>
          )}
          <div className="flex staking-btn justify-between">
            {actionToggle !== "staking" && (
              <Button
                label="Cancel"
                transparent={true}
                customClass="px28 py12 btn-style mr10"
                onClick={() => onCancelHandler()}
              />
            )}
            <Button
              label={`${headerType !== "" ? "" : "Unstake"}`}
              transparent={true}
              customClass="px28 py12 btn-style mr10"
              onClick={unstakeHandler}
            />
            <Button
              label={`${headerType !== "" ? headerType : "Stake"}`}
              customClass="px50 py12 btn-style"
              onClick={() =>
                headerType
                  ? setIsModalVisible(true)
                  : setActionToggle && setActionToggle(toggleStake)
              }
              disabled={headerType ? !data?.selectedMonk?.length && !data?.selectedBooster?.length : false}
            />
          </div>
        </div>
        <MonkModal
          isModalVisible={isModalVisible}
          title_img={confirmation_animation_emoji}
          title={`Are you sure you want to ${headerType.toLowerCase()}? (Not live, test mode)`}
          description="Please confirm you want unstake your monke/booster by clicking Unstake."
          buttonPrimary={{
            label: headerType,
            onClick: onClickHandler,
          }}
          buttonSecondary={{
            label: "Cancel",
            onClick: onCloseModal,
          }}
          onClose={() => setIsModalVisible(false)}
        />
        {/* <MonkModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            title_img={true_arrow}
            title="Your staking request has been successfully created"
            description="You can see the transaction status in your wallet."
            buttonPrimary={{
              label: "Continue"
            }}
            buttonSecondary={{
              label: "View transaction on Etherscan"
            }}
          /> */}
      </div>
    </StakeBalanceComponentWrapper>
  );
};

StakeBalanceComponent.propTypes = {};

StakeBalanceComponent.defaultProps = {};

export default StakeBalanceComponent;
