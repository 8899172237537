import React, { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import MonkeBank from "containers/MonkeBank";
import Staking from "containers/Staking";
import Boosters from "containers/Boosters";
import Trainers from "containers/Trainers";
// import Treasurehunt from "containers/TreasureHunt";
// import Marketplace from "containers/MarketPlace";
import UpgradeBooster from "containers/Boosters/UpgradeBooster";
import ApplyBooster from "containers/Boosters/ApplyBooster";
import UnapplyBooster from "containers/Boosters/UnapplyBooster";

interface DashboardRoutesProps {}

const DashboardRoutes: FunctionComponent<DashboardRoutesProps> = () => {
  return (
    <Routes>
      <Route path="/monkebank" element={<MonkeBank />} />
      <Route path="/staking" element={<Staking />} />
      <Route path="/boosters" element={<Boosters />} />
      <Route path="/trainers" element={<Trainers />} />
      {/* <Route path="/treasurehunt" element={<Treasurehunt />} /> */}
      {/* <Route path="/marketplace" element={<Marketplace />} /> */}
      <Route path="/upgrade%20boosters" element={<UpgradeBooster />} />
      <Route path="/apply%20boosters" element={<ApplyBooster />} />
      <Route path="/unapply%20boosters" element={<UnapplyBooster />} />
    </Routes>
  );
};

export default DashboardRoutes;
