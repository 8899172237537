import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { price_coin } from "helper/constants";

const CMBAmountWrapper = styled.div`
  @media (max-width: 991.98px) {
    .price {
      font-size: 12px;
    }
  }
`;

interface CMBAmountProps {
  amount: number;
  redCheckbox: boolean;
}

const CMBAmount: FunctionComponent<CMBAmountProps> = ({
  amount,
  redCheckbox,
}) => {
  return (
    <CMBAmountWrapper>
      <div className="white--text">
        <div className="font-12 regular-text lightgray--text">$CMB</div>
        <div className="font-14 extra-bold-text price">
          {amount}
          <img
            src={price_coin}
            alt="coin"
            className={`${redCheckbox ? "hidden" : "ml5"}`}
          />
        </div>
      </div>
    </CMBAmountWrapper>
  );
};

CMBAmount.propTypes = {};

CMBAmount.defaultProps = {};

export default CMBAmount;
