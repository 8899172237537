import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { staking_empty_img } from "helper/constants";

const StakingEmptyWrapper = styled.div`
  .staking-empty {
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh - 65px);
    display: flex;
  }
  .transparent-btn {
    width: 184px;
    height: 40px;
    font-family: "Nunito Sans";
    background: transparent;
    border: 2px solid #c4c6d0;
  }
  .openSea_trainer_btn {
    width: 184px;
    height: 40px;
    background: #00cbe0;
  }
  .openSea_btn {
    width: 139px;
    height: 40px;
    background: #00cbe0;
  }
`;

interface StakingEmptyProps {
  title: string;
  discription: string;
  transparentBtn?: boolean;
}

const StakingEmpty: FunctionComponent<StakingEmptyProps> = ({
  title,
  discription,
  transparentBtn = false,
}) => {
  return (
    <StakingEmptyWrapper>
      <div className="staking-empty">
        <div>
          <img src={staking_empty_img} alt="staking_empty_img" />
          <div className="font-24 extra-bold-text white--text">{title}</div>
          <div className="font-16 regular-text lightgray--text mt15">
            {discription}
          </div>
          {transparentBtn ? (
            <div className="flex justify-center mt24">
              <button className="transparent-btn br8 font-12 extra-bold-text lightgray--text mr10">
                Learn more about Trainers
              </button>
              <button className="openSea_trainer_btn font-12 white--text br8 border-none cursor-pointer">
                Go to Open Sea
              </button>
            </div>
          ) : (
            <button className="openSea_btn font-12 white--text br8 border-none cursor-pointer mt24">
              Go to Open Sea
            </button>
          )}
        </div>
      </div>
    </StakingEmptyWrapper>
  );
};

StakingEmpty.propTypes = {};

StakingEmpty.defaultProps = {};

export default StakingEmpty;
