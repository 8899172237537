import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Button } from "components/common/Button";
import { price_coin } from "helper/constants";
import { useSelector } from "react-redux";
import { getMonkeBankValue } from "redux/selectors/monkeBank";

const TrainersHeaderWrapper = styled.div`
  .title-description {
    width: 56%;
  }
  @media (max-width: 991.98px) {
    .claim-section {
      flex-direction: column;
    }
    .balance-btn-section {
      align-self: flex-start;
      margin-top: 16px;
    }
    .btn-style {
      padding: 5px 19px;
    }
    .title-description {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    .balance-btn-section {
      flex-direction: column;
    }
    .claim-btn {
      margin-top: 20px;
      padding-left: 0px;
    }
  }
`;

interface TrainersHeaderProps {
  setIsModalVisible?: any;
  showButton?: any;
  setShowButton?: any;
  onCancle?: () => void;
  requiredCMB?: number
}

const TrainersHeader: FunctionComponent<TrainersHeaderProps> = ({
  setIsModalVisible,
  showButton,
  setShowButton,
  onCancle,
  requiredCMB
}) => {
  const balance = useSelector(getMonkeBankValue);

  return (
    <TrainersHeaderWrapper>
      <div className="flex secondary px20 py11 br4 justify-between claim-section">
        <div className="title-description">
          <div className="flex mb6 ">
            <div className="font-16 extra-bold-text self-center lh-32 mr15">
              Select the Monkes to Claim
            </div>
            <Button
              label={"Learn more about trainers"}
              transparent={true}
              customClass="px12 py6"
            />
          </div>
          <div className="font-14 lightgray--text lh-19">
            You can select multiple Monkes at once (claiming multiple trainers
            will save you gas!). After your transaction is complete, you will
            get a Trainer in your Open Sea account.
          </div>
        </div>
        <div className="flex self-center balance-btn-section">
          <div className="flex">
            <div className="pr20 border-right self-center">
              <div className="font-12 regular-text lightgray--text">
                Game $CMB Balance:
              </div>
              <div className="font-18 extra-bold-text">
                {balance?.balance.toFixed(2)}{" "}
                <img src={price_coin} alt="coin" />
              </div>
            </div>
            <div className="px20 self-center">
              <div className="font-12 regular-text lightgray--text">
                Required $CMB:
              </div>
              <div className="font-18 extra-bold-text">
                {requiredCMB} <img src={price_coin} alt="coin" />
              </div>
            </div>
          </div>
          {!showButton ? (
            <div className="claim-btn">
              <Button
                label="Claim Trainer"
                customClass="px32 py10 btn-style"
                onClick={() => setShowButton(true)}
              />
            </div>
          ) : (
            <div className="flex pa5">
              <Button
                label="Cancel"
                transparent={true}
                customClass="px28 py12 btn-style mr10"
                onClick={() => {setShowButton(false); onCancle && onCancle()}}
              />
              <Button
                label="Confirm"
                customClass="px32 py12 btn-style"
                onClick={() => setIsModalVisible(true)}
              />
            </div>
          )}
        </div>
      </div>
    </TrainersHeaderWrapper>
  );
};

TrainersHeader.propTypes = {};

TrainersHeader.defaultProps = {};

export default TrainersHeader;
