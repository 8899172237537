import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import TrainersHeader from "components/trainers/TrainersHeader";
import MonkCard from "./../../components/common/MonkCard";
import AppLoader from "components/common/AppLoader";
import { useSelector } from "react-redux";
import { getLoading, getStaked } from "redux/selectors/monkeBank";
import { BOOST_URL, MONKE_URL } from "utils/contstants";
import MonkModal from "components/common/Modal";
import { monkeGreenIcon, monkeYellowIcon, monkeRedIcon } from "helper/constants";
import CMBAmount from "components/common/MonkCard/CMBAmount";
import { useNavigate } from "react-router-dom";
import StakingEmpty from "../../components/staking/StakingEmpty";

const TrainersWrapper = styled.div``;

interface TrainersProps {}

const Trainers: FunctionComponent<TrainersProps> = () => {
  const loading = useSelector(getLoading);
  const stakedValue = useSelector(getStaked);
  const navigate = useNavigate();
  const [selectedMonks, setSelectedMonks] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [successfullyModal, setSuccessfulyModal] = useState(false);
  const [showButton, setShowButton] = useState<boolean>(false);

  const monkedCardData = useMemo(() => {
    const monkesCard: Array<any> = [];
    if (stakedValue?.monkes) {
      Object.keys(stakedValue?.trainers_to_claim)?.forEach((key: any) => {
        monkesCard.push({
          id: key,
          cmb: stakedValue?.monkes[key],
          img: `${MONKE_URL}${key}.png`,
          booster: stakedValue?.active_boosters[key],
          boosterImg: `${BOOST_URL}${stakedValue?.active_boosters[key]}`,
        });
      });
    }
    return monkesCard;
  }, [stakedValue]);

  const requiredCMB =  useMemo(() => {
    if(selectedMonks?.length){
      const requiredAmount = selectedMonks.reduce((key:any,data:any) =>  key = key + data.cmb , 0 )
      return requiredAmount;
    }
    return 0;
  },[selectedMonks]);

  const trainersToClaimCheck = useMemo(
    () =>
      !!stakedValue &&
      stakedValue?.trainers_to_claim &&
      Object.keys(stakedValue?.trainers_to_claim),
    [stakedValue]
  );

  const selectChangeHandler = (data: any) => {
    const selectedRepeat = selectedMonks.find(
      (monk: any) => monk.id === data.id
    );
    if (data.id !== selectedRepeat?.id) {
      setSelectedMonks([...selectedMonks, data]);
    } else {
      setSelectedMonks(
        selectedMonks.filter((monk: any) => {
          return monk.id !== data.id;
        })
      );
    }
  };

  const onCanclehandler = () => {
    setSelectedMonks([]);
  }

  if (
    !loading &&
    Object.keys(stakedValue?.trainers_to_claim || {})?.length === 0
  ) {
    return (
      <StakingEmpty
        title="You don't seem to have any Monkes to claim."
        discription="Open Open Sea and Purchase one to start earning"
        transparentBtn={true}
      />
    );
  }

  const handleClaimTrainer = () => {
    console.log(selectedMonks, "Claim trainer successful");
    setSuccessfulyModal(true);
    setIsModalVisible(false);
  };

  if (loading) {
    return <AppLoader />;
  }

  const handleOpenSea = () => {
    console.log("Open Sea Handler");
  };

  const handleMonkeBank = () => {
    console.log("Monke bank Handler");
  };
  return (
    <TrainersWrapper>
      <div className="ma20">
        <div>
          <TrainersHeader
            setIsModalVisible={setIsModalVisible}
            showButton={showButton}
            setShowButton={setShowButton}
            onCancle={onCanclehandler}
            requiredCMB={requiredCMB}
          />
        </div>
      </div>
      <div className="ml20 my20">
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Monkes</div>
          <div className="flex sider pb20">
            {monkedCardData?.length > 0 &&
              monkedCardData?.map(
                (card: any) =>
                  trainersToClaimCheck.includes(card?.id) && (
                    <MonkCard
                      src={card.img}
                      cardName="Monke"
                      cardId={card.id}
                      tagType="stake"
                      cmbAmount={card.cmb}
                      cardActionButton={{
                        type: "boost",
                        onClick: () => navigate("/dashboard/apply%20boosters"),
                        isShow: true,
                      }}
                      isChecked={selectedMonks?.some(
                        (monk: any) => monk.id === card.id
                      )}
                      checkBoxType={showButton ? "primary" : null}
                      onSelectChange={() => showButton ? selectChangeHandler(card) : ""}
                    />
                  )
              )}
          </div>
        </div>
        <MonkModal
          isModalVisible={isModalVisible}
          title_img={monkeYellowIcon}
          title="Are you sure you want to claim trainers?"
          description="To claim, you need 0 $CMB. Confirm the action? (Not live, test mode)"
          buttonPrimary={{
            label: "Claim",
            onClick: handleClaimTrainer,
          }}
          buttonSecondary={{
            label: "Cancel",
            onClick: () => setIsModalVisible(false)
          }}
          onClose={() => setIsModalVisible(false)}
        />
        <MonkModal
          isModalVisible={successfullyModal}
          title_img={monkeGreenIcon}
          title="Transaction is successfull (Not live, test mode)"
          description="Find the trainer in your Open Sea account."
          buttonPrimary={{
            label: "Open Sea",
            onClick: handleOpenSea,
          }}
          buttonSecondary={{
            label: "Monke Bank",
            onClick: handleMonkeBank,
          }}
          onClose={() => setSuccessfulyModal(false)}
        />
      </div>
    </TrainersWrapper>
  );
};

Trainers.propTypes = {};

Trainers.defaultProps = {};

export default Trainers;
