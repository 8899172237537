import React, { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { removeAppToken } from "redux/actions/auth";
import Store from './../../../redux/store';
import { useNavigate } from "react-router-dom";
import {
  arrow_left,
  arrow_right,
  guide_icon,
  iconcard_wallet,
  icon_launch,
  icon_menu_close,
  icon_monkey,
  icon_monke_bank,
  icon_treasurehunt,
  icon_marketplace,
  logout_icon,
  logo_navbar_mobile,
  mobile_menubar,
  mobile_nav_earnmore,
  sidebar_bottom_img,
  sidebar_logo,
} from "helper/constants";

const getMetaMaskToken = () => {
  var metamask_token = Store.getState().auth.metaMask.token;
  var display_token = metamask_token.substring(0, 6) + "........." + metamask_token.slice(metamask_token.length - 6);
  return display_token;
}

const SidebarWrapper = styled.div`
  .sidebar-container,
  .sidebar-title,
  .navlink {
    width: 250px;
    transform: translateX(0px);
    transition: 0.3s ease-out;
  }
  .sidebar-container-close {
    width: 83px;
  }
  .sidebar-container-close,
  .side-logo {
    transform: translateX(0px);
    transition: 0.3s ease-in-out;
  }
  a {
    text-decoration: none;
  }
  .routes-sec {
    display: grid;
    grid-gap: 16px;
  }
  .arrow-left {
    position: absolute;
    right: 9px;
    top: 27px;
  }
  .notification-count {
    position: absolute;
    height: 24px;
    width: 24px;
    right: 20px;
    border-radius: 50%;
    visibility: hidden; 
  }
  .notification-count span {
    top: 4.9px;
    left: 4.7px;
  }
  .arrow-right {
    position: absolute;
    right: 5px;
    top: 27px;
  }
  .navlink {
    gap: 22px;
  }
  .navlink-side {
    row-gap: 7px;
  }
  .navlink:hover,
  .navlink-side:hover {
    .side-border {
      display: block;
    }
    .icon {
      filter: invert(0.1) sepia(1) saturate(7) hue-rotate(352.8deg);
    }
    .link_text {
      color: #fbc937;
    }
  }
  .side-border {
    display: none;
    position: absolute;
    height: 45px;
    width: 5px;
    background: #fbc937;
    top: 0px;
    right: 0;
    border-radius: 6px 0 0 6px;
  }
  .active {
    .side-border {
      display: block;
    }
    .icon {
      filter: invert(0.1) sepia(1) saturate(7) hue-rotate(352.8deg);
    }
    .link_text {
      color: #fbc937;
    }
  }
  .arrow:hover,
  .guide-sidebar:hover,
  .logout-icon:hover {
    filter: invert(0) sepia(1) brightness(1.2);
  }
  .mobile-menubar,
  .mobile-nav {
    display: none;
  }
  @media (max-width: 991.98px) {
    .web-sidebar {
      display: none;
    }
    .mobile-nav {
      display: flex;
    }
    .nav-top {
      height: calc(100vh - 70.7px);
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 50.7px);
      position: absolute;
      top: 63px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
    .nav-menu.active2 {
      left: 0px;
      top: 63px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  }
  @media (max-width: 575.98px) {
    .nav-top {
      align-self: center;
    }
  }
  @media (max-width: 375.98px) {
    .nav-top {
      align-self: center;
    }
    .nav-items {
      margin-top: 20px;
    }
    .wallet-logout {
      padding: 0 15px;
    }
    .icon {
      padding-left: 15px;
    }
  }
`;

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface NavProps {
  isActive: boolean;
}

const routes = [
  {
    path: "/dashboard/monkebank",
    name: "Monke Bank",
    icon: icon_monke_bank,
    notification_count: null,
  },
  {
    path: "/dashboard/staking",
    name: "Staking",
    icon: iconcard_wallet,
    notification_count: null,
  },
  {
    path: "/dashboard/boosters",
    name: "Boosters",
    icon: icon_launch,
    notification_count: null,
  },
  {
    path: "/dashboard/trainers",
    name: "Trainers",
    icon: icon_monkey,
    notification_count: null,
  },
  // {
  //   path: "/dashboard/treasurehunt",
  //   name: "Treasure Hunt",
  //   icon: icon_treasurehunt,
  //   notification_count: null,
  // },
  // {
  //   path: "/dashboard/marketplace",
  //   name: "Marketplace",
  //   icon: icon_marketplace,
  //   notification_count: null,
  // },
];


const Sidebar: FunctionComponent<SidebarProps> = ({ isOpen, setIsOpen }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const disconnectWalletToken = () => {
    dispatch(removeAppToken());
    navigate("/");
  };


  return (
    <div className="sticky-sidebar">
      <SidebarWrapper>
        <div>
          <div
            className={`${
              isOpen ? "sidebar-container" : "sidebar-container-close"
            } full-height secondary sidebar-container-main flex flex-col justify-between web-sidebar`}
          >
            <div className="top-section">
              <div className="relative side-logotext">
                {isOpen ? (
                  <div className="white--text font-24 pt20 pb36 pl25 extra-bold-text sidebar-title">
                    Cool{" "}
                    <img
                      src={sidebar_logo}
                      alt="logo"
                      style={{ position: "relative", top: "-3px" }}
                    />{" "}
                    <span className="lightgray--text font-24 regular-text">
                      Monkes
                    </span>
                  </div>
                ) : (
                  <div className="pt18 pl22 pr23 pb48">
                    <img src={sidebar_logo} alt="logo" className="side-logo" />
                  </div>
                )}
                {isOpen ? (
                  <img
                    src={arrow_left}
                    alt="arrow"
                    className="arrow arrow-left cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                ) : (
                  <img
                    src={arrow_right}
                    alt="arrow"
                    className="arrow arrow-right cursor-pointer"
                    onClick={() => setIsOpen(true)}
                  />
                )}
              </div>
              <section className="routes-sec">
                {routes.map((route) => (
                  <NavLink
                    to={route.path}
                    key={route.name}
                    className={`${(navData: NavProps) =>
                      navData.isActive ? "active" : ""} relative flex ${
                      isOpen
                        ? "navlink pl32 py10"
                        : "navlink-side flex-col items-center text-center pl5"
                    }`}
                  >
                    <img src={route.icon} alt="icon" className="icon" />
                    {isOpen ? (
                      <div className="link_text font-18 lightgray--text extra-bold-text">
                        {route.name}
                      </div>
                    ) : (
                      <div className="link_text font-10 lightgray--text extra-bold-text">
                        {route.name}
                      </div>
                    )}
                    {isOpen ? (
                      <div
                        className={`${
                          route.notification_count
                            ? "notification-count yellow font-12 secondary--text extra-bold-text"
                            : ""
                        }`}
                      >
                        <span className="relative">
                          {route.notification_count}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="side-border"></div>
                  </NavLink>
                ))}
              </section>
            </div>
            <div className="bottom-section text-center">
              {isOpen ? (
                <img
                  src={sidebar_bottom_img}
                  alt="Open Guides"
                  className="cursor-pointer mb32"
                />
              ) : (
                <div className="guide-sidebar cursor-pointer mb14">
                  <img src={guide_icon} alt="guide" />
                  <div className="font-10 extra-bold-text pt7 lightgray--text">
                    Guides
                  </div>
                </div>
              )}
              {isOpen ? (
                <div className="flex justify-between items-center ml32 mr23 mb30">
                  <div className="text-left">
                    <div className="font-14 regular-text lightgray--text">
                      Wallet id:
                    </div>
                    <div className="font-14 extra-bold-text white--text">
                      {getMetaMaskToken()}
                    </div>
                  </div>
                  <img
                    src={logout_icon}
                    alt="logout"
                    className="cursor-pointer logout-icon"
                    onClick={disconnectWalletToken}
                  />
                </div>
              ) : (
                <div className="cursor-pointer mb20 logout-icon" onClick={disconnectWalletToken}>
                  <img
                    src={logout_icon}
                    alt="logout"
                    className="cursor-pointer"
                  />
                  <div className="font-10 extra-bold-text pt7 lightgray--text">
                    Log out
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* MOBILE - TABLET VIEW START */}
          <div
            className={`flex justify-between secondary mobile-nav sticky-header ${
              click ? "px19 py10" : "px19 py7"
            }`}
          >
            <div className="flex">
              <img
                src={logo_navbar_mobile}
                alt="logo"
                className="self-center"
              />
              {click ? (
                ""
              ) : (
                <div className="pl8 pt5">
                  <div className="font-20 lh-15 extra-bold-text pt5">Cool</div>
                  <div className="font-19 regular-text lightgray--text">
                    Monkes
                  </div>
                </div>
              )}
            </div>
            {click ? (
              <div className="font-20 lh-15 extra-bold-text pt15"> Menu</div>
            ) : (
              ""
            )}
            <div className={click ? "nav-menu primary active2" : "nav-menu"}>
              <div className="flex flex-col justify-between nav-top">
                <div className="mt35 nav-items">
                  {routes.map((route) => (
                    <NavLink
                      to={route.path}
                      key={route.name}
                      className={`${(navData: NavProps) =>
                        navData.isActive ? "active" : ""} relative flex my30`}
                      onClick={handleClick}
                    >
                      <img src={route.icon} alt="icon" className="icon" />
                      <div className="font-20 lightgray--text extra-bold-text pl22 link_text">
                        {route.name}
                      </div>
                    </NavLink>
                  ))}
                  <img
                    src={mobile_nav_earnmore}
                    alt="earn more"
                    className="cursor-pointer"
                  />
                </div>
                <div className="flex justify-between items-center mb30 wallet-logout">
                  <div className="text-left">
                    <div className="font-14 regular-text lightgray--text">
                      Wallet id:
                    </div>
                    <div className="font-14 extra-bold-text white--text">
                      {getMetaMaskToken()}
                    </div>
                  </div>
                  <img
                    onClick={disconnectWalletToken}
                    src={logout_icon}
                    alt="logout"
                    className="cursor-pointer logout-icon"
                  />
                </div>
              </div>
            </div>
            <div className="self-center" onClick={handleClick}>
              {click ? (
                <img src={icon_menu_close} alt="close" />
              ) : (
                <img src={mobile_menubar} alt="menu" />
              )}
            </div>
          </div>
        </div>
      </SidebarWrapper>
    </div>
  );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
