import React, { FunctionComponent } from "react";
import styled from "styled-components";

const ResourceBoxWrapper = styled.div`
  .resource-box {
    min-width: 119px;
  }
  @media (max-width: 575.98px) {
    .resource-box {
      display: block;
      min-width: 54px;
      margin-right: 0 !important;
    }
    .resource-icon {
      height: 41px;
      width: 41px;
      margin-bottom: 6px;
      margin-right: 0px;
    }
    .resource-count {
      font-size: 10px !important;
      max-width: 40px;
    }
  }
`;

interface ResourceBoxProps {
  icon: string;
  title: string;
  count: string;
}

const ResourceBox: FunctionComponent<ResourceBoxProps> = ({
  icon,
  title,
  count,
}) => {
  return (
    <ResourceBoxWrapper>
      <div className="flex primary br4 py6 px7 resource-box mr10">
        <img
          src={icon}
          alt="icon_wood"
          className="pa5 mr13 secondary br4 resource-icon"
        />
        <div className="self-end ">
          <div className="font-12 lightgray--text regular-text pb5">
            {title}
          </div>
          <div className="font-24 extra-bold-text pb0 lh-22 pr10 resource-count">
            {count}
          </div>
        </div>
      </div>
    </ResourceBoxWrapper>
  );
};

ResourceBox.propTypes = {};

ResourceBox.defaultProps = {};

export default ResourceBox;
