import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  icon_gem,
  icon_seed,
  icon_stone,
  icon_vine,
  icon_wood,
} from "helper/constants";
import ResourceBox from "components/monkebank/Resources/ResourceBox";

const ResourcesWrapper = styled.div`
  @media (max-width: 575px) {
    .mobile_box {
      justify-content: space-between;
      margin-right: 15px;
    }
  }
`;

interface ResourcesProps {
  balance: any;
}

const Resources: FunctionComponent<ResourcesProps> = ({ balance }) => {
  const { Wood=0, Stone=0, Seed=0, Vine=0, Gem=0 } = balance?.resources || {};

  return (
    <ResourcesWrapper>
      <div className="font-18 extra-bold-text pb10">Resources</div>
      <div className="font-12 regular-text lightgray--text pb14 pr18">
        Every day you get resources for upgraded boosters that are attached to
        Monkes.
      </div>
      <div className="flex mobile_box">
        <ResourceBox icon={icon_wood} title="Wood:" count={Wood?.toFixed(2)} />
        <ResourceBox icon={icon_stone} title="Stone:" count={Stone?.toFixed(2)} />
        <ResourceBox icon={icon_seed} title="Seed:" count={Seed?.toFixed(2)} />
        <ResourceBox icon={icon_vine} title="Vine:" count={Vine?.toFixed(2)} />
        <ResourceBox icon={icon_gem} title="Gem:" count={Gem?.toFixed(2)} />
      </div>
    </ResourcesWrapper>
  );
};

Resources.propTypes = {};

Resources.defaultProps = {};

export default Resources;
