import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { boost_button_rocket } from "helper/constants";
import { useNavigate } from "react-router-dom";

const CardButtonWrapper = styled.div`
  .btn {
    width: auto;
    border: 1px solid #fbc937;
  }
  @media (max-width: 991.98px) {
    .rocket-img {
      display: none;
    }
    .btn-text {
      padding: 4px 16px;
    }
  }
`;

interface CardButtonProps {
  monke?: any;
  boostUpgradeId?: any;
}

export const CardButton: FunctionComponent<CardButtonProps> = ({
  monke,
  boostUpgradeId,
}) => {
  const navigate = useNavigate();

  const onUpgradeHandler = (id: any) => {
    !monke && navigate(`/dashboard/upgrade%20boosters?id=${id}`);
  };

  return (
    <CardButtonWrapper>
      <div
        className="btn font-12 flex br8 cursor-pointer transparent"
        onClick={() => {
          !monke
            ? onUpgradeHandler(boostUpgradeId)
            : navigate("/dashboard/apply%20boosters");
        }}
      >
        <div className="px10 self-center rocket-img">
          <img src={boost_button_rocket} alt="btn-img" />
        </div>
        <div className="pr9 py7 yellow--text semi-bold-text btn-text">
          {monke ? "Boost me" : "Upgrade"}
        </div>
      </div>
    </CardButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  .solid-btn {
    font-family: "Nunito Sans";
    border: 2px solid #fbc937;
  }
  .solid-btn:hover {
    background: #d8ac2d;
    border: 2px solid #d8ac2d;
  }
  .transparent-btn {
    font-family: "Nunito Sans";
    background: transparent;
    border: 2px solid #c4c6d0;
  }
  .transparent-btn:hover {
    color: #fff !important;
    border: 2px solid #fff;
  }
  .disable_btn,
  .disable_btn:hover {
    border: 1.5px solid #707380;
    color: #707380 !important;
    cursor: not-allowed;
  }
  .custom-button {
    cursor: pointer;
  }
  .custom-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

interface ButtonProps {
  label?: string;
  transparent?: boolean;
  customClass?: string;
  onClick?: () => void;
  disabled?: boolean;
  btnwidth?: number
}

export const Button: FunctionComponent<ButtonProps> = ({
  label,
  transparent,
  customClass,
  onClick,
  btnwidth,
  disabled = false,
}) => {
  return (
    <ButtonWrapper>
      {label && (
        <button
          onClick={onClick}
          className={`custom-button br8 font-12 ${customClass} ${
            transparent
              ? "transparent-btn lightgray--text"
              : "solid-btn yellow secondary--text"
          } extra-bold-text`}
          disabled={disabled}
          style={{width: `${btnwidth}px`}}
        >
          {label}
        </button>
      )}
    </ButtonWrapper>
  );
};

Button.propTypes = {};

Button.defaultProps = {};
