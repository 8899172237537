import { AppDispatch } from "../store";
import { setMetaMaskToken } from "redux/reducers/auth";

export const appToken: any = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(setMetaMaskToken(data));
};

export const removeAppToken: any = () => async (dispatch: AppDispatch) => {
  dispatch(setMetaMaskToken({token: null}));
};
