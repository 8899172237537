import { combineReducers } from '@reduxjs/toolkit'
import monkeBank from './monkeBank';
import auth from './auth';

const rootReducer = combineReducers({
  monkeBank,
  auth
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer