import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getURLToken } from "redux/selectors/auth";

const ProtectedRoute = ({ Component }: any) => {
  const reduxStoreToken = useSelector(getURLToken);
  return reduxStoreToken ? <Component /> : <Navigate to="/" />;
};

export default ProtectedRoute;
