import React, { FunctionComponent } from "react";
import styled from "styled-components";

const IconWIthLabelWrapper = styled.div`
  @media (max-width: 991.98px) {
    .widget-id {
      font-size: 12px;
    }
    .booster-img {
      width: 24px !important;
      height: 24px !important;
      margin-top: 3px;
    }
    .widget-booster {
      padding-left: 5px;
    }
  }
`;

interface IconWIthLabelProps {
  labelId: string;
  labelIcon: string;
  labelName: string;
}

const IconWIthLabel: FunctionComponent<IconWIthLabelProps> = ({
  labelId,
  labelIcon,
  labelName,
}) => {
  return (
    <IconWIthLabelWrapper>
      <div className="self-center flex">
        <img
          src={labelIcon}
          alt="img"
          style={{ width: "32px", height: "32px" }}
          className="booster-img"
        />

        <div className="pl9 widget-booster">
          <div className="font-12 regular-text lightgray--text">
            {labelName}
          </div>
          <div className="font-14 extra-bold-text widget-id">
            {`# ${labelId}`}
          </div>
        </div>
      </div>
    </IconWIthLabelWrapper>
  );
};

IconWIthLabel.propTypes = {};

IconWIthLabel.defaultProps = {};

export default IconWIthLabel;
