import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import MonkeTransactionCard from "components/monkebank/MonkeTransactionCard";
import MonkCard from "components/common/MonkCard";
import {
  confirmation_animation_emoji,
  deposite_big_coin,
  deposite_mobile_coin,
  icon_diamond,
  monkeGreenIcon,
  monkebank_blue_bg,
  monkebank_blue_mobile,
  monkebank_yellow_bg,
  monkebank_yellow_mobile,
  monkeYellowIcon,
  monkeRedIcon,
  price_coin,
  withdrawn_diamond,
  withdrawn_mobile_diamond,
  cmb_abi,
  cmb_contract
} from "helper/constants";
import Resources from "components/monkebank/Resources";
import { useSelector } from "react-redux";
import {
  getLoading,
  getMonkeBankValue,
  getStaked,
  getUnstakedMonkes,
  getUnstakedBoost,
} from "redux/selectors/monkeBank";
import AppLoader from "components/common/AppLoader";
import { MONKE_URL, BOOST_URL } from "utils/contstants";
import MonkModal from "components/common/Modal";
import Store from './../../redux/store';
import { useNavigate } from "react-router-dom";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import MonkeBankAPI from "services/monkeBank.service";
import { getURLToken } from "redux/selectors/auth";

const MonkeBankWrapper = styled.div`
  .diamond-btn {
    border: 2px solid #c4c6d0;
  }
  @media (max-width: 991.98px) {
    .transaction-card {
      margin-right: 24px;
    }
    .main-bottom-section {
      margin: 20px 0px 20px 22px;
    }
  }
  @media (max-width: 575.98px) {
    .main-top-section {
      margin: 18px;
    }
    .main-bottom-section {
      margin: 20px 0px 20px 22px;
    }
    .transaction-card {
      margin-right: 10px;
    }
    .font-24 {
      font-size: 20px;
    }
    .stake-component {
      padding-left: 15px;
    }
    .staking-1 {
      padding-right: 20px;
    }
    .staking-2 {
      padding: 0 20px;
    }
    .staking-3 {
      padding: 0 20px;
    }
  }
  @media (max-width: 375.98px) {
    .main-top-section {
      margin: 12px;
    }
    .resource-section {
      padding-left: 10px;
    }
    .main-bottom-section {
      margin: 20px 0px 20px 12px;
    }
  }
`;

interface MonkeBankProps {}

const MonkeBank: FunctionComponent<MonkeBankProps> = () => {
  const loading = useSelector(getLoading);
  const balance = useSelector(getMonkeBankValue);
  const stakedValue = useSelector(getStaked);
  const unstakedMonkes = useSelector(getUnstakedMonkes);
  const unstakedBoost = useSelector(getUnstakedBoost);
  const [isModalDeposit, setIsModalDeposit] = useState(false);
  const [isModalWithdraw, setIsModalWithdraw] = useState(false);
  const [depositeSuccessModal, setDepositeSuccessModal] = useState(false);
  const [depositeSuccessModalError, setDepositeSuccessModalError] = useState(false);
  const [isModalWithdrawalRequest, setIsModalWithdrawalRequest] = useState(false);
  const [isModalWithdrawalRequestError, setIsModalWithdrawalRequestError] = useState(false);
  const withdrawalAmount = (balance?.ERC20 || 0) * (100-Number(balance?.tax_rate))/100.0;
  const [withdrawValue, setWithdrawValue] = useState<number | null>(null);
  const [transactionLink, setTransactionLink] = useState<string | null>(null);
  const navigate = useNavigate();
  const monkeBankAPI = new MonkeBankAPI();
  const reduxStoreToken = useSelector(getURLToken);

  const activeBooster = useMemo(() => {
    if (stakedValue?.active_boosters) {
      const data: Record<string, string> = stakedValue.active_boosters;
      const newData = Object.keys(data).reduce(function (
        obj: Record<string, string>,
        key: string
      ) {
        obj[data[key]] = key;
        return obj;
      },
      {});
      return newData;
    }
    return {};
  }, [stakedValue.active_boosters]);

  const monkedCardData = useMemo(() => {
    const monkesCard: Array<any> = [];
    if (stakedValue?.monkes) {
      Object.keys(stakedValue?.monkes)?.forEach((key: any) => {
        monkesCard.push({
          id: key,
          cmb: stakedValue?.monkes[key],
          img: `${MONKE_URL}${key}.png`,
          booster: stakedValue?.active_boosters[key],
          boosterImg: `${BOOST_URL}${stakedValue?.active_boosters[key]}`,
        });
      });
    }
    return monkesCard;
  }, [stakedValue]);

  const boostCardData = useMemo(() => {
    const boostsCard: Array<any> = [];
    if (stakedValue?.boosts) {
      Object.keys(stakedValue?.boosts)?.forEach((key: any) => {
        boostsCard.push({
          id: key,
          tier_rate: stakedValue?.boosts[key],
          img: `${BOOST_URL}${key}`,
          attached_monk:
            activeBooster.hasOwnProperty(key) && activeBooster[key],
          boosts_to_upgrade: { id: key },
        });
      });
    }
    return boostsCard;
  }, [stakedValue]);

  const unstakedMonkesData = useMemo(() => {
    const unstakedMonkesCard: Array<Object> = [];
    if (unstakedMonkes) {
      Object.keys(unstakedMonkes)?.forEach((key: string) => {
        unstakedMonkesCard.push({
          id: key,
          cmb: unstakedMonkes[key],
          img: `${MONKE_URL}${key}.png`,
        });
      });
    }
    return unstakedMonkesCard;
  }, [unstakedMonkes]);

  const openEtherscan = () => {
    window.open("https://etherscan.io/address/" + Store.getState().auth.metaMask.token, '_blank', 'noopener,noreferrer');
  };

  const unstakedBoostData = useMemo(() => {
    const unstakedBoostCard: Array<Object> = [];
    if (unstakedBoost) {
      Object.keys(unstakedBoost)?.forEach((key: string) => {
        unstakedBoostCard.push({
          id: key,
          tier_rate: unstakedBoost[key],
          img: `${BOOST_URL}${key}`,
        });
      });
    }
    return unstakedBoostCard;
  }, [unstakedBoost]);

  const getWalletSigner = async () => {
    const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];

    const providerOptions = {
      /* See Provider Options Section */
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
    return signer;
  };

  const depositeToMonkBankHandler = async () => {
    console.log("depositRequestHandler");
    const cmb_amount = BigInt(Number(withdrawValue) * 10 ** 18);
    const signer = await getWalletSigner();
    const erc20_rw = new ethers.Contract(cmb_contract, cmb_abi, signer);

    try {
      const gasAmount = await erc20_rw.estimateGas.burn(cmb_amount);
      const register = await erc20_rw.burn((cmb_amount), {
        gasLimit: gasAmount
      });
  
      const transactionHash = register["hash"];    

      setDepositeSuccessModal(true);
      setIsModalDeposit(false);
      setTransactionLink("https://etherscan.io/tx/" + transactionHash);
    } catch (error) {
      setDepositeSuccessModalError(true);
      setIsModalDeposit(false)
    }
  };

  const withDrawRequestHandler = async () => {
    console.log("withDrawRequestHandler");
    const cmb_amount = BigInt(Number(withdrawValue) * 10 ** 18);
    const signer = await getWalletSigner();
    var response = await monkeBankAPI.getWithdrawalValidate(reduxStoreToken);
    var parsedResponse = JSON.parse(response.data);
    const erc20_rw = new ethers.Contract(cmb_contract, cmb_abi, signer);

    try {
      const gasAmount = await erc20_rw.estimateGas.mint(reduxStoreToken, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"]);
      const register = await erc20_rw.mint(reduxStoreToken, parsedResponse["amount"], parsedResponse["nounce"], parsedResponse["signature"], {
        gasLimit: gasAmount
      });
  
      const transactionHash = register["hash"];
      setIsModalWithdrawalRequest(true);
      setIsModalWithdraw(false)
      setTransactionLink("https://etherscan.io/tx/" + transactionHash);
    } catch (error) {
      // console.log("Error has occurred.");
      // console.log();
      setIsModalWithdrawalRequestError(true);
      setIsModalWithdraw(false)
    }
  }

  const monkBankDepositeHandler = () => {
    console.log("monkBankDepositeHandler");
    window.location.reload();
  }
  
  const monkBankDepositErrorHandler = () => {
    console.log("monkBankDepositHandlerError");
    setDepositeSuccessModalError(false);
  }

  const monkBankWithDrawalHandler = () => {
    console.log("monkBankWithDrawalHandler");
    window.location.reload();
  }

  const monkBankWithDrawalErrorHandler = () => {
    console.log("monkBankWithDrawalHandlerError");
    setIsModalWithdrawalRequestError(false);
  }

  if (loading) {
    return <AppLoader />;
  }

  return (
    <MonkeBankWrapper>
      <div className="ma20 main-top-section">
        <div className="flex">
          <div className="mr40 transaction-card">
            <MonkeTransactionCard
              icon={deposite_big_coin}
              mobile_icon={deposite_mobile_coin}
              background={monkebank_yellow_bg}
              mobile_background={monkebank_yellow_mobile}
              title="Game $CMB Balance:"
              amount={Number(balance?.balance?.toFixed(2)) || 0}
              btn_title="Deposit to Bank"
              onClick={() => setIsModalDeposit(true)}
            />
          </div>
          <MonkeTransactionCard
            icon={withdrawn_diamond}
            mobile_icon={withdrawn_mobile_diamond}
            background={monkebank_blue_bg}
            mobile_background={monkebank_blue_mobile}
            title="ERC-20 $CMB Balance:"
            amount={Number(balance?.ERC20?.toFixed(2)) || 0}
            btn_title="Withdraw to ERC-20"
            onClick={() => setIsModalWithdraw(true)}
          />
        </div>
        <div className="pl21 pt18 pb15 secondary br4 resource-section">
          <Resources balance={balance} />
        </div>
        <div className="secondary pl20 pt15 pb19 mt20 br4 stake-component">
          <div className="flex">
            <div className="font-18 extra-bold-text self-center pr20">
              Staking
            </div>
            <div className="font-12 extra-bold-text lightgray--text diamond-btn py5 px10 flex br10 cursor-pointer" onClick={openEtherscan}>
              <img src={icon_diamond} alt="diamond" className="pr10" />
              <span className="self-center">Open Etherscan</span>
            </div>
          </div>
          <div className="flex mt15">
            <div className="pr30 border-right staking-1">
              <div className="font-12 regular-text lightgray--text">
                Yield per Day, $CMB:
              </div>
              <div className="font-24 extra-bold-text">
                {balance?.yield} {""}
                <img src={price_coin} alt="coin" className="vert-baseline" />
              </div>
            </div>
            <div className="px30 border-right self-center staking-2">
              <div className="font-12 regular-text lightgray--text">
                Staked:
              </div>
              <div className="font-24 extra-bold-text">
                {monkedCardData?.length + boostCardData?.length}
              </div>
            </div>
            <div className="px30 self-center staking-3">
              <div className="font-12 regular-text lightgray--text">
                Unstaked:
              </div>
              <div className="font-24 extra-bold-text">
                {unstakedMonkesData?.length + unstakedBoostData?.length}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml22 my22 main-bottom-section">
        <div className="my20">
          <div className="font-24 extra-bold-text mb20">Monkes</div>
          <div className="flex sider pb20">
            {monkedCardData?.length > 0 &&
              monkedCardData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Monk"
                  cardId={card.id}
                  tagType="stake"
                  cardActionButton={{
                    type: "boost",
                    onClick: () => navigate("/dashboard/apply%20boosters"),
                    isShow: !card.booster,
                  }}
                  cmbAmount={card.cmb}
                  iconWithLabel={{
                    id: card.booster,
                    icon: card?.boosterImg,
                    label: "Booster",
                    isShow: card?.booster,
                  }}
                />
              ))}
            {unstakedMonkesData?.length > 0 &&
              unstakedMonkesData?.map((card: any) => (
                <MonkCard
                  src={card.img}
                  cardName="Monke"
                  cardId={card.id}
                  tagType="unstake"
                  cmbAmount={card.cmb}
                  cardActionButton={{
                    type: "boost",
                    onClick: () => navigate("/dashboard/apply%20boosters"),
                    isShow: true,
                  }}
                />
              ))}
          </div>
        </div>
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Boost Passes</div>
          <div className="flex sider pb20">
            {boostCardData?.length > 0 &&
              boostCardData?.map((card: any) => (
                <MonkCard
                src={card?.img}
                cardName="Booster"
                tagType={card.attached_monk ? "monk" : "stake"}
                tagId={card.attached_monk ? card.attached_monk : null}
                cardId={card.id}
                cardActionButton={{
                  type: "upgrade",
                  onClick: () => navigate("/dashboard/upgrade%20boosters"),
                  isShow: true,
                }}
                tier={{
                  tierPercentage: card.tier_rate,
                  tierValue: 1,
                }}
              />
              ))}
            {unstakedBoostData?.length > 0 &&
              unstakedBoostData?.map((card: any) => (
                <MonkCard
                src={card?.img}
                cardName="Booster"
                cardId={card.id}
                tagType="unstake"
                cardActionButton={{
                  type: "upgrade",
                  onClick: () => navigate("/dashboard/upgrade%20boosters"),
                  isShow: true,
                }}
                tier={{
                  tierPercentage: card.tier_rate,
                  tierValue: 1,
                }}
              />
              ))}
          </div>
        </div>
        <MonkModal
          isModalVisible={isModalDeposit}
          title_img={confirmation_animation_emoji}
          title="Deposit to MonkeBank"
          description="Please enter the amount you want to deposit:"
          cmbDeposite={{
            label: "confirm",
            value: withdrawValue,
            onChange: (value: number) => setWithdrawValue(value),
            onClick: depositeToMonkBankHandler,
            isReadOnly: false
          }}
          onClose={() => setIsModalDeposit(false)}
        />
        <MonkModal
          isModalVisible={depositeSuccessModal}
          title_img={monkeGreenIcon}
          title="Deposit Requested!"
          description={`Monke around while the MonkeBank receives your ${withdrawValue} $CMB`}
          buttonPrimary={{
            label: "Monke Bank",
            onClick: monkBankDepositeHandler
          }}
          buttonSecondary={{
            label: "View transaction on Etherscan",
            link: transactionLink
          }}
          onClose={() => setDepositeSuccessModal(false)}
        />
        <MonkModal
          isModalVisible={depositeSuccessModalError}
          title_img={monkeRedIcon}
          title="Deposit Failed!"
          description="We encountered an error while processing your deposit! Please ensure you have $CMB available for deposit and have ETH to cover transaction gas."
          buttonPrimary={{
            label: "Monke Bank",
            onClick: monkBankDepositErrorHandler
          }}
          onClose={() => setDepositeSuccessModalError(false)}
        />
        <MonkModal
          isModalVisible={isModalWithdraw}
          title_img={monkeYellowIcon}
          title="Withdraw Request"
        
          description={"Are you sure you want to withdraw all? Your current tax rate is " + balance?.tax_rate + ".00%. You will receive approximately " + (Number(balance?.balance) * Number(((100.0 - Number(balance?.tax_rate))/100)))?.toFixed(2) + " $CMB from your withdrawal:"}
          buttonPrimary={{
            label: "confirm",
            onClick: withDrawRequestHandler
          }}
          onClose={() => setIsModalWithdraw(false)}
        />
        <MonkModal
          isModalVisible={isModalWithdrawalRequest}
          title_img={monkeGreenIcon}
          title="Withdrawal Requested!"
          description="Monke around while your wallet receives your $CMB"
          buttonPrimary={{
            label: "Monke Bank",
            onClick: monkBankWithDrawalHandler
          }}
          buttonSecondary={{
            label: "View transaction on Etherscan",
            link: transactionLink
          }}
          onClose={() => setIsModalWithdrawalRequest(false)}
        />
        <MonkModal
          isModalVisible={isModalWithdrawalRequestError}
          title_img={monkeRedIcon}
          title="Withdrawal Failed!"
          description="We encountered an error while processing your withrawal! Please ensure you have $CMB available for withdrawal and have ETH to cover transaction gas."
          buttonPrimary={{
            label: "Monke Bank",
            onClick: monkBankWithDrawalErrorHandler
          }}
          onClose={() => setIsModalWithdrawalRequestError(false)}
        />
      </div>
    </MonkeBankWrapper>
  );
};

MonkeBank.propTypes = {};

MonkeBank.defaultProps = {};

export default MonkeBank;
