import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import MonkCard from "components/common/MonkCard";
import { useSelector } from "react-redux";
import { getLoading, getStaked, getUnstakedMonkes } from "redux/selectors/monkeBank";
import { BOOST_URL, MONKE_URL } from "utils/contstants";
import AppLoader from "components/common/AppLoader";
import MonkModal from "components/common/Modal";
import { monkeGreenIcon } from "helper/constants";
import { Button } from "components/common/Button";
import { useNavigate } from "react-router-dom";

const ApplyBoosterWrapper = styled.div`
  .title-description {
    width: 56%;
  }
  .claim-btn {
    padding-left: 20px;
  }
  @media (max-width: 991.98px) {
    .claim-section {
      flex-direction: column;
    }
    .balance-btn-section {
      align-self: flex-start;
      margin-top: 16px;
    }
    .btn-style {
      padding: 5px 19px;
    }
    .title-description {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    .balance-btn-section {
      flex-direction: column;
    }
    .claim-btn {
      margin-top: 20px;
      padding-left: 0px;
    }
  }
`;

interface ApplyBoosterProps {}

const ApplyBooster: FunctionComponent<ApplyBoosterProps> = () => {
  const stakedValue = useSelector(getStaked);
  const loading = useSelector(getLoading);
  const unstakedMonkes = useSelector(getUnstakedMonkes);
  let navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [selectedMonk, setSelectedMonk] = useState<any>(null);
  const [selectedBooster, setSelectedBooster] = useState<any>(null);

  const activeBooster = useMemo(() => {
    if (stakedValue?.active_boosters) {
      const data: Record<string, string> = stakedValue.active_boosters;
      const newData = Object.keys(data).reduce(function (
        obj: Record<string, string>,
        key: string
      ) {
        obj[data[key]] = key;
        return obj;
      },
      {});
      return newData;
    }
    return {};
  }, [stakedValue.active_boosters]);

  const boostCardData = useMemo(() => {
    const boostsCard: Array<any> = [];
    if (stakedValue?.boosts) {
      Object.keys(stakedValue?.boosts)?.forEach((key: any) => {
        boostsCard.push({
          id: key,
          tier_rate: stakedValue?.boosts[key],
          img: `${BOOST_URL}${key}`,
          attached_monk:
            activeBooster.hasOwnProperty(key) && activeBooster[key],
          boosts_to_upgrade: { id: key },
        });
      });
    }
    return boostsCard;
  }, [stakedValue]);

  const monkedCardData = useMemo(() => {
    const monkesCard: any = [];
    if (stakedValue?.monkes) {
      Object.keys(stakedValue?.monkes)?.forEach((key: any) => {
        monkesCard.push({
          id: key,
          cmb: stakedValue?.monkes[key],
          img: `${MONKE_URL}${key}.png`,
          booster: stakedValue?.active_boosters[key],
          boosterImg: `${BOOST_URL}${stakedValue?.active_boosters[key]}`,
        });
      });
    }
    return monkesCard;
  }, [stakedValue]);

  const unstakedMonkesData = useMemo(() => {
    const unstakedMonkesCard: Array<Object> = [];
    if (unstakedMonkes) {
      Object.keys(unstakedMonkes)?.forEach((key: string) => {
        unstakedMonkesCard.push({
          id: key,
          cmb: unstakedMonkes[key],
          img: `${MONKE_URL}${key}.png`,
        });
      });
    }
    return unstakedMonkesCard;
  }, [unstakedMonkes]);

  const selectChangeHandler = (type: string, data: any) => {
    if (type === "monk") {
      setSelectedMonk(data);
    } else if (type === "booster") {
      setSelectedBooster(data);
    }
  };

  const applyBoosterHandler = () => {
    console.log("selectedMonk && selectedBooster", selectedMonk, selectedBooster);
    
    setSuccessModal(true)
  }

  const boostSuccessContinueHandler = () => {
    console.log("boostSuccessContinueHandler")
    setSuccessModal(false);
  }

  if (loading) {
    return <AppLoader />;
  }

  return (
    <ApplyBoosterWrapper>
      <div className="ma20">
        <div className="flex justify-between secondary br4 px20 pt15 pb17 stake-section">
          <div>
            <div className="font-16 extra-bold-text pb5">
              Select the Monke you want to apply booster to
            </div>
            <div className="font-14 regular-text lightgray--text">
              After clicking on the Monke, choose the boost pass and press
              Apply. You can choose only one card to boost.
            </div>
          </div>
          <div className="flex">
            {selectedMonk && selectedBooster && (
              <Button
                label="Cancel"
                transparent={true}
                customClass="px16 py10 btn-style"
                disabled={false}
                onClick={() => navigate("/dashboard/boosters")}
              />
            )}
            <Button
              label="Apply boosters"
              customClass="px25 py10 btn-style ml10"
              disabled={!(selectedMonk && selectedBooster)}
              onClick={applyBoosterHandler}
            />
          </div>
        </div>
      </div>

      <div className="ma20">
        <div className="font-24 extra-bold-text mb20">Boost Passes</div>
        <div className="flex sider pb20">
          {boostCardData?.length > 0 &&
            boostCardData?.map((card: any) => (
              <MonkCard
                src={card?.img}
                cardName="Booster"
                tagType={card.attached_monk ? "monk" : "stake"}
                tagId={card.attached_monk ? card.attached_monk : null}
                cardId={card.id}
                cardActionButton={{
                  type: "upgrade",
                  onClick: () => {},
                  isShow: true,
                }}
                tier={{
                  tierPercentage: card.tier_rate,
                  tierValue: 1,
                }}
                checkBoxType="primary"
                isChecked={selectedBooster?.id === card.id}
                onSelectChange={() => selectChangeHandler("booster", card)}
                disabled={selectedBooster && selectedBooster?.id !== card.id}
              />
            ))}
        </div>
        <div className="font-24 extra-bold-text mb20">Monkes</div>
        <div className="flex sider pb20">
          {monkedCardData?.length > 0 &&
            monkedCardData?.map((card: any) => (
              <MonkCard
                src={card?.img}
                cardName="Monk"
                cardId={card.id}
                tagType="stake"
                cardActionButton={{
                  type: "boost",
                  onClick: () => {},
                  isShow: !card.booster,
                }}
                cmbAmount={card.cmb}
                checkBoxType="primary"
                isChecked={selectedMonk?.id === card.id}
                onSelectChange={() => selectChangeHandler("monk", card)}
                iconWithLabel={{
                  id: card.booster,
                  icon: card?.boosterImg,
                  label: "Booster",
                  isShow: card?.booster,
                }}
                disabled={selectedMonk && selectedMonk?.id !== card.id}
              />
            ))}
            {unstakedMonkesData?.length > 0 &&
              unstakedMonkesData?.map((card: any) => (
                <MonkCard
                  src={card.img}
                  cardName="Monke"
                  cardId={card.id}
                  tagType="unstake"
                  cmbAmount={card.cmb}
                  cardActionButton={{
                    type: "boost",
                    onClick: () => {},
                    isShow: true,
                  }}
                  checkBoxType={"primary"}
                  isChecked={selectedMonk?.id === card.id}
                  onSelectChange={() => selectChangeHandler("monk", card)}
                  disabled={selectedMonk && selectedMonk?.id !== card.id}
                />
              ))}
        </div>
        <MonkModal
          isModalVisible={successModal}
          title_img={monkeGreenIcon}
          title="Booster successfully applied (Not live, test mode)"
          description="You can see results on Monke Bank"
          buttonPrimary={{
            label: "Continue",
            onClick: boostSuccessContinueHandler
          }}
          onClose={() => setSuccessModal(false)}
        />
      </div>
    </ApplyBoosterWrapper>
  );
};

ApplyBooster.propTypes = {};

ApplyBooster.defaultProps = {};

export default ApplyBooster;
