import React, { FunctionComponent } from "react";
import styled from "styled-components";

const TierPercentageWrapper = styled.div``;

interface TierPercentageProps {
	tierValue: number;
	tierPercentage: number;
}

const TierPercentage: FunctionComponent<TierPercentageProps> = ({
	tierValue,
	tierPercentage
}) => {
  return (
    <TierPercentageWrapper>
      <div className="widget-price white--text">
        <div className="font-12 regular-text lightgray--text">
					Tier: {tierValue}
        </div>
        <div className="font-14 extra-bold-text price">
          {tierPercentage}%
        </div>
      </div>
    </TierPercentageWrapper>
  );
};

TierPercentage.propTypes = {};

TierPercentage.defaultProps = {};

export default TierPercentage;
