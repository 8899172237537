import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Button } from "antd";
import Header from "components/landing/header";
import useDevice from "hooks/useDevice";
import { webMap, mobileMap, water } from "helper/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getURLToken } from "redux/selectors/auth";

const OnePageWrapper = styled.div`
  background: #78d7f4;
  height: 100vh;
  .title {
    max-width: 470px;
    margin: 0 auto;
  }
  .exploreBtn_position {
    position: absolute;
    left: 50%;
    bottom: -110px;
    transform: translate(-50%, -50%);
  }
  .explor_btn {
    background: #fbc937;
    color: #343a55;
    border-radius: 8px;
    width: 200px;
    height: 48px;
    border: none;
  }
  .map_img {
    width: 100%;
    background: #78d7f4;
  }
  .bg-img {
    background: url(${water});
    width: 100%;
    height: 100vh;
  }

  @media (max-width: 575px) {}
`;

interface OnePageProps {}

const OnePage: FunctionComponent<OnePageProps> = () => {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxStoreToken = useSelector(getURLToken);
  const enterBank = () => {
    navigate("dashboard/monkebank");
  };

  return (
    <OnePageWrapper>
      <Header />

      <div className="relative">
        <div
          className={`${
            isMobile ? "font-36 pt50" : "font-64 pt40"
          } extra-bold-text white--text text-center title`}
        >
          Fun PvE and PvP Experience
        </div>
        { reduxStoreToken && (
          <div className="flex justify-center exploreBtn_position">
            <Button className="explor_btn font-14 extra-bold-text"
              onClick={enterBank}>
              Enter Bank
            </Button>
          </div>
          )
        }

      </div>
      {/* <div className="bg-img"> */}
      <img src={isMobile ? mobileMap : webMap} alt="map" className="map_img" />
      {/* </div> */}
    </OnePageWrapper>
  );
};

OnePage.propTypes = {};

OnePage.defaultProps = {};

export default OnePage;
