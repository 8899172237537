import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBalance, IMonkeBank } from "../../interface/monkeBank";

const initialState: IMonkeBank = {
  loading: false,
  balance: null,
  staked: {},
  unstakedMonkes: {},
  unstakedBoost: {},
};

const monkeBank = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setBalance(state, action: PayloadAction<IBalance>) {
      return {
        ...state,
        balance: action.payload,
      };
    },
    setStaked(state, action: PayloadAction<any>) {
      return {
        ...state,
        staked: action.payload,
      };
    },
    setUnstakedMonkes(state, action: PayloadAction<any>) {
      return {
        ...state,
        unstakedMonkes: action.payload,
      };
    },
    setUnstakedBoost(state, action: PayloadAction<any>) {
      return {
        ...state,
        unstakedBoost: action.payload,
      };
    },
  },
});

export const {
  setLoading,
  setBalance,
  setStaked,
  setUnstakedMonkes,
  setUnstakedBoost,
} = monkeBank.actions;

export default monkeBank.reducer;
