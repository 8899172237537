import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Button } from "components/common/Button";
import { monkeGreenIcon, monkeYellowIcon, price_coin } from "helper/constants";
import { useSelector } from "react-redux";
import { getStaked } from "redux/selectors/monkeBank";
import { BOOST_URL } from "utils/contstants";
import MonkModal from "components/common/Modal";
import { getMonkeBankValue } from "redux/selectors/monkeBank";
import MonkCard from "components/common/MonkCard";

const UpgradeBoosterWrapper = styled.div`
  .title-description {
    width: 56%;
  }
  @media (max-width: 991.98px) {
    .claim-section {
      flex-direction: column;
    }
    .balance-btn-section {
      align-self: flex-start;
      margin-top: 16px;
    }
    .btn-style {
      padding: 5px 19px;
    }
    .title-description {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    .balance-btn-section {
      flex-direction: column;
    }
    .claim-btn {
      margin-top: 20px;
      padding-left: 0px;
    }
  }
`;

interface UpgradeBoosterProps {}

const UpgradeBooster: FunctionComponent<UpgradeBoosterProps> = () => {
  const stakedValue = useSelector(getStaked);
  const location = useLocation();
  const upgradeBoosterId = new URLSearchParams(location.search).get("id");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const balance = useSelector(getMonkeBankValue);
  const [selectedBoosters, setselectedBoosters] = useState<any>([]);
  const [showButton, setShowButton] = useState<boolean>(false);

  const selectChangeHandler = (data: any) => {
    if (selectedBoosters.includes(data)) {
      setselectedBoosters(selectedBoosters.filter((f: any) => f !== data));
    } else {
      setselectedBoosters([...selectedBoosters, data]);
    }
  };

  const activeBooster = useMemo(() => {
    if (stakedValue?.active_boosters) {
      const data: Record<string, string> = stakedValue.active_boosters;
      const newData = Object.keys(data).reduce(function (
        obj: Record<string, string>,
        key: string
      ) {
        obj[data[key]] = key;
        return obj;
      },
      {});
      return newData;
    }
    return {};
  }, [stakedValue.active_boosters]);

  const boostCardData = useMemo(() => {
    const boostsCard: Array<any> = [];
    if (stakedValue?.boosts) {
      Object.keys(stakedValue?.boosts)?.forEach((key: any) => {
        boostsCard.push({
          id: key,
          tier_rate: stakedValue?.boosts[key],
          img: `${BOOST_URL}${key}`,
          attached_monk:
            activeBooster.hasOwnProperty(key) && activeBooster[key],
          boosts_to_upgrade: { id: key },
        });
      });
    }
    return boostsCard;
  }, [stakedValue]);

  const handleBoosters = () => {
    console.log(selectedBoosters, "Claim trainer successful");
    setSuccessModal(true);
    setIsModalVisible(false);
  };

  const handleBoostersClick = () => {
    console.log("Booster Handler");
  };

  return (
    <UpgradeBoosterWrapper>
      <div className="ma20">
        <div className="flex secondary px20 py11 br4 justify-between claim-section">
          <div className="title-description">
            <div className="flex mb6 ">
              <div className="font-16 extra-bold-text self-center lh-32 mr15">
                Select the Boost passes to Upgrade
              </div>
            </div>
            <div className="font-14 lightgray--text lh-19">
              Check out the required $CMB amount. If you have enough resources,
              you will upgrade the Boost passes successfully.
            </div>
          </div>
          <div className="flex self-center balance-btn-section">
            <div className="flex">
              <div className="pr20 border-right self-center">
                <div className="font-12 regular-text lightgray--text">
                  Game $CMB Balance:
                </div>
                <div className="font-18 extra-bold-text">
                  {balance?.balance.toFixed(2)}{" "}
                  <img src={price_coin} alt="coin" />
                </div>
              </div>
              <div className="px20 self-center">
                <div className="font-12 regular-text lightgray--text">
                  Required $CMB:
                </div>
                <div className="font-18 extra-bold-text">
                  0 <img src={price_coin} alt="coin" />
                </div>
              </div>
            </div>
            {!showButton ? (
              <div className="claim-btn">
                <Button
                  label="Upgrade Boosters"
                  customClass="px32 py10 btn-style"
                  onClick={() => setShowButton(true)}
                />
              </div>
            ) : (
              <div className="flex pa5">
                <Button
                  label="Cancel"
                  transparent={true}
                  customClass="px28 py12 btn-style mr10"
                  onClick={() => {setShowButton(false); setselectedBoosters([])}}
                />
                <Button
                  label="Confirm"
                  customClass="px32 py12 btn-style"
                  onClick={() => setIsModalVisible(true)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Boost Passes</div>
          <div className="flex sider pb20">
            {boostCardData?.length > 0 &&
              boostCardData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Booster"
                  tagType={card.attached_monk ? "monk" : "stake"}
                  tagId={card.attached_monk ? card.attached_monk : null}
                  cardId={card.id}
                  isChecked={selectedBoosters.includes(card?.id)}
                  checkBoxType={showButton ? "primary" : null}
                  onSelectChange={() => showButton ? selectChangeHandler(card.id) : ""}
                  tier={{
                    tierValue: 1,
                    tierPercentage: card.tier_rate,
                  }}
                  iconWithLabel={{
                    id: card.booster,
                    icon: card?.boosterImg,
                    label: "Booster",
                    isShow: card?.booster,
                  }}
                />
              ))}
          </div>
        </div>
        <MonkModal
          isModalVisible={isModalVisible}
          title_img={monkeYellowIcon}
          title="Are you sure you want to upgrade you boost passes? (Not live, test mode)"
          description="Upgrade amount 0 $CMB, if you want to confirm the action click below"
          buttonPrimary={{
            label: "Upgrade",
            onClick: handleBoosters,
          }}
          buttonSecondary={{
            label: "Cancel",
            onClick: () => setIsModalVisible(false)
          }}
          onClose={() => setIsModalVisible(false)}
        />
        <MonkModal
          isModalVisible={successModal}
          title_img={monkeGreenIcon}
          title="Your boosters have been successfully upgraded (Not live, test mode)"
          buttonPrimary={{
            label: "Boosters",
            onClick: handleBoostersClick,
          }}
          onClose={() => setSuccessModal(false)}
        />
      </div>
    </UpgradeBoosterWrapper>
  );
};

UpgradeBooster.propTypes = {};

UpgradeBooster.defaultProps = {};

export default UpgradeBooster;
