import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  icon_checkbox_empty,
  icon_checkbox_fill,
  icon_checkbox_remove_empty,
  icon_checkbox_remove_fill,
} from "helper/constants";

const CheckboxWrapper = styled.div`
  @media (max-width: 991.98px) {
    .remove-checkbox {
      height: 24px;
      width: 24px;
    }
  }
`;

interface CheckboxProps {
  isChecked: boolean;
  greenCheckbox?: boolean;
  redCheckbox?: boolean;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  isChecked,
  greenCheckbox,
  redCheckbox,
}) => {
  return (
    <CheckboxWrapper>
      {greenCheckbox && (
        <div className="cursor-pointer">
          <img
            src={`${isChecked ? icon_checkbox_fill : icon_checkbox_empty}`}
            alt="checkbox"
          />
        </div>
      )}
      {redCheckbox && (
        <div className="cursor-pointer ml5">
          <img
            src={`${
              isChecked ? icon_checkbox_remove_fill : icon_checkbox_remove_empty
            }`}
            alt="checkbox"
            className="remove-checkbox"
          />
        </div>
      )}
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {};

Checkbox.defaultProps = {};

export default Checkbox;
