import React, { FunctionComponent, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getLoading,
  getStaked,
  getUnstakedBoost,
} from "redux/selectors/monkeBank";
import { BOOST_URL } from "utils/contstants";
import { Button } from "components/common/Button";
import MonkCard from "components/common/MonkCard";
import AppLoader from "components/common/AppLoader";

const BoostersWrapper = styled.div`
  @media (max-width: 575.98px) {
    .stake-section {
      flex-direction: column;
    }
    .btn-style {
      margin-top: 12px;
      padding: 7px 43px;
    }
  }
`;

interface BoostersProps {}

const Boosters: FunctionComponent<BoostersProps> = () => {
  const stakedValue = useSelector(getStaked);
  const unstakedBoost = useSelector(getUnstakedBoost);
  const loading = useSelector(getLoading);
  const navigate = useNavigate();

  const activeBooster = useMemo(() => {
    if (stakedValue?.active_boosters) {
      const data: Record<string, string> = stakedValue.active_boosters;
      const newData = Object.keys(data).reduce(function (
        obj: Record<string, string>,
        key: string
      ) {
        obj[data[key]] = key;
        return obj;
      },
      {});
      return newData;
    }
    return {};
  }, [stakedValue.active_boosters]);

  const boostCardData = useMemo(() => {
    const boostsCard: Array<any> = [];
    if (stakedValue?.boosts) {
      Object.keys(stakedValue?.boosts)?.forEach((key: any) => {
        boostsCard.push({
          id: key,
          tier_rate: stakedValue?.boosts[key],
          img: `${BOOST_URL}${key}`,
          attached_monk:
            activeBooster.hasOwnProperty(key) && activeBooster[key],
          boosts_to_upgrade: { id: key },
        });
      });
    }
    return boostsCard;
  }, [stakedValue]);

  const unstakedBoostData = useMemo(() => {
    const unstakedBoostCard: Array<Object> = [];
    if (unstakedBoost) {
      Object.keys(unstakedBoost)?.forEach((key: string) => {
        unstakedBoostCard.push({
          id: key,
          tier_rate: unstakedBoost[key],
          img: `${BOOST_URL}${key}`,
        });
      });
    }
    return unstakedBoostCard;
  }, [unstakedBoost]);

  if (loading) {
    return <AppLoader />;
  }

  return (
    <BoostersWrapper>
      <div className="ma20">
        <div className="flex justify-between secondary br4 px20 pt15 pb17 stake-section">
          <div>
            <div className="font-16 extra-bold-text pb5">
              This are your boost passes
            </div>
            <div className="font-14 regular-text lightgray--text">
              You can apply them to Cool Monkes to improve your earnings. Find
              out, if you have enough SCMB to upgrade them.
            </div>
          </div>
          <div className="flex">
            <Button
              label="Unapply boosters"
              transparent={true}
              customClass="px16 py10 btn-style"
              onClick={() => navigate("/dashboard/unapply%20boosters")}
            />
            <Button
              label="Apply boosters"
              customClass="px25 py10 btn-style ml10"
              onClick={() => navigate("/dashboard/apply%20boosters")}
            />
          </div>
        </div>
      </div>

      <div className="ml20 my20">
        <div className="mt30">
          <div className="font-24 extra-bold-text mb20">Boost Passes</div>

          <div className="flex sider pb20">
            {boostCardData?.length > 0 &&
              boostCardData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Booster"
                  tagType={card.attached_monk ? "monk" : "stake"}
                  tagId={card.attached_monk ? card.attached_monk : null}
                  cardId={card.id}
                  cardActionButton={{
                    type: "upgrade",
                    onClick: () => navigate("/dashboard/upgrade%20boosters"),
                    isShow: true,
                  }}
                  tier={{
                    tierPercentage: card.tier_rate,
                    tierValue: 1,
                  }}
                />
              ))}
            {unstakedBoostData?.length > 0 &&
              unstakedBoostData?.map((card: any) => (
                <MonkCard
                  src={card?.img}
                  cardName="Booster"
                  cardId={card.id}
                  tagType="unstake"
                  tier={{
                    tierPercentage: card.tier_rate,
                    tierValue: 1,
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </BoostersWrapper>
  );
};

Boosters.propTypes = {};

Boosters.defaultProps = {};

export default Boosters;
