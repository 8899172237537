import { AppDispatch } from "../store";
import {
  setLoading,
  setBalance,
  setStaked,
  setUnstakedMonkes,
  setUnstakedBoost,
} from "redux/reducers/monkeBank";
import MonkeBankAPI from "services/monkeBank.service";
import Store from './../store'

const getMetaMaskToken = () => {
  const state = Store.getState()
  return state.auth.metaMask.token
}

export const getUserBalance: any = () => async (dispatch: AppDispatch) => {
  const metaMaskToken = getMetaMaskToken()
  const monkeBankAPI = new MonkeBankAPI();
  dispatch(setLoading(true));

  const response = await monkeBankAPI.getUserBalance(metaMaskToken);
  if (response.status === 200) {
    dispatch(setLoading(false));
    dispatch(setBalance(response.data));
  }
};

export const getStakedTokens: any = () => async (dispatch: AppDispatch) => {
  const metaMaskToken = getMetaMaskToken()
  const monkeBankAPI = new MonkeBankAPI();
  dispatch(setLoading(true));

  const response = await monkeBankAPI.getStakedTokens(metaMaskToken);
  if (response.status === 200) {
    dispatch(setLoading(false));
    dispatch(setStaked(response.data));
  }
};

export const getTokens: any = () => async (dispatch: AppDispatch) => {
  const metaMaskToken = getMetaMaskToken()
  const monkeBankAPI = new MonkeBankAPI();
  dispatch(setLoading(true));

  const response = await monkeBankAPI.getTokens(metaMaskToken);
  if (response.status === 200) {
    dispatch(setLoading(false));
    dispatch(setUnstakedMonkes(response.data));
  }
};

export const getBoostTokens: any = () => async (dispatch: AppDispatch) => {
  const metaMaskToken = getMetaMaskToken()
  const monkeBankAPI = new MonkeBankAPI();
  dispatch(setLoading(true));

  const response = await monkeBankAPI.getBoostTokens(metaMaskToken);
  if (response.status === 200) {
    dispatch(setLoading(false));
    dispatch(setUnstakedBoost(response.data));
  }
};
