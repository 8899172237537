import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import MonkCard from "components/common/MonkCard";
import { useSelector } from "react-redux";
import { getLoading, getStaked } from "redux/selectors/monkeBank";
import { BOOST_URL, MONKE_URL } from "utils/contstants";
import AppLoader from "components/common/AppLoader";
import MonkModal from "components/common/Modal";
import { monkeGreenIcon, monkeYellowIcon } from "helper/constants";
import { Button } from "components/common/Button";
import { useNavigate } from "react-router-dom";

const UnapplyBoosterWrapper = styled.div`
  .title-description {
    width: 56%;
  }
  .claim-btn {
    padding-left: 20px;
  }
  @media (max-width: 991.98px) {
    .claim-section {
      flex-direction: column;
    }
    .balance-btn-section {
      align-self: flex-start;
      margin-top: 16px;
    }
    .btn-style {
      padding: 5px 19px;
    }
    .title-description {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    .balance-btn-section {
      flex-direction: column;
    }
    .claim-btn {
      margin-top: 20px;
      padding-left: 0px;
    }
  }
`;

interface UnapplyBoosterProps {}

const UnapplyBooster: FunctionComponent<UnapplyBoosterProps> = () => {
  const stakedValue = useSelector(getStaked);
  const loading = useSelector(getLoading);
  let navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedMonk, setSelectedMonk] = useState<any>([]);

  const monkedCardData = useMemo(() => {
    const monkesCard: any = [];
    if (stakedValue?.monkes) {
      Object.keys(stakedValue?.monkes)?.forEach((key: any) => {
        monkesCard.push({
          id: key,
          cmb: stakedValue?.monkes[key],
          img: `${MONKE_URL}${key}.png`,
          booster: stakedValue?.active_boosters[key],
          boosterImg: `${BOOST_URL}${stakedValue?.active_boosters[key]}`,
        });
      });
    }
    return monkesCard;
  }, [stakedValue]);

  const selectChangeHandler = (type: string, data: any) => {
    if (type === "monk") {
      const selectedRepeat = selectedMonk.find(
        (monk: any) => monk.id === data.id
      );
      if (data.id !== selectedRepeat?.id) {
        setSelectedMonk([...selectedMonk, data]);
      } else {
        setSelectedMonk(
          selectedMonk.filter((monk: any) => {
            return monk.id !== data.id;
          })
        );
      }
    }
  };

  const activeBoosterCheck = useMemo(
    () =>
      !!stakedValue &&
      stakedValue?.active_boosters &&
      Object.keys(stakedValue?.active_boosters),
    [stakedValue]
  );

  const unApplyBoostConfirmHandler = () => {
    console.log("unApplyBoostConfirmHandler");
    setIsModalVisible(true);
  };

  const unApplyBoostHandler = () => {
    console.log("unApplyBoostHandler", selectedMonk);
    setSuccessModal(true);
    setIsModalVisible(false);
  };

  const gotoContinueHandler = () => {
    console.log("gotoContinueHandler");
    setSuccessModal(false);
  };

  if (loading) {
    return <AppLoader />;
  }

  return (
    <UnapplyBoosterWrapper>
      <div className="ma20">
        <div className="flex justify-between secondary br4 px20 pt15 pb17 stake-section">
          <div>
            <div className="font-16 extra-bold-text pb5">
              Select the Monke you want to remove the booster from. Click on it
            </div>
            <div className="font-14 regular-text lightgray--text">
              After your transaction is complete, you will get a notification in
              your crypto wallet.
            </div>
          </div>
          <div className="flex">
            {selectedMonk && (
              <Button
                label="Cancel"
                transparent={true}
                customClass="px16 py10 btn-style"
                disabled={false}
                onClick={() => navigate("/dashboard/boosters")}
              />
            )}
            <Button
              label="Unapply boosters"
              customClass="px25 py10 btn-style ml10"
              disabled={!selectedMonk?.length}
              onClick={unApplyBoostConfirmHandler}
            />
          </div>
        </div>
      </div>

      <div className="ma20">
        <div className="font-24 extra-bold-text mb20">Monkes</div>
        <div className="flex sider pb20">
          {monkedCardData?.length > 0 &&
            monkedCardData?.map(
              (card: any) => (
                  <MonkCard
                    src={card?.img}
                    cardName="Monk"
                    cardId={card.id}
                    tagType="stake"
                    cmbAmount={card.cmb}
                    checkBoxType="secondary"
                    isChecked={selectedMonk?.some(
                      (monk: any) => monk.id === card.id
                    )}
                    onSelectChange={() => selectChangeHandler("monk", card)}
                    iconWithLabel={{
                      id: card.booster,
                      icon: card?.boosterImg,
                      label: "Booster",
                      isShow: card?.booster,
                    }}
                  />
                )
            )}
        </div>
        <MonkModal
          isModalVisible={isModalVisible}
          title_img={monkeYellowIcon}
          title="Are you sure you want unapply boosters from the Monke?"
          description="All boosters would be available to stake or use for another monke"
          buttonPrimary={{
            label: "Unapply",
            onClick: unApplyBoostHandler,
          }}
          buttonSecondary={{
            label: "Cancel",
            onClick: () => setIsModalVisible(false),
          }}
          onClose={() => setIsModalVisible(false)}
        />
        <MonkModal
          isModalVisible={successModal}
          title_img={monkeGreenIcon}
          title="Booster successfully unapplied (Not live, test mode)"
          description="You can see results on Monke Bank"
          buttonPrimary={{
            label: "Continue",
            onClick: gotoContinueHandler,
          }}
          onClose={() => setSuccessModal(false)}
        />
      </div>
    </UnapplyBoosterWrapper>
  );
};

UnapplyBooster.propTypes = {};

UnapplyBooster.defaultProps = {};

export default UnapplyBooster;
