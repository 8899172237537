import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  metaMask: {
    token: "",
  },
};

const monkeBank = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMetaMaskToken(state, action: PayloadAction<any>) {
      const { token } = action.payload;
      return {
        ...state,
        metaMask: { ...state.metaMask, token },
      };
    },
  },
});

export const { setMetaMaskToken } = monkeBank.actions;

export default monkeBank.reducer;
