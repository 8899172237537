import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  // error_animation_emoji,
  // modal_green_check,
} from "helper/constants";

const MonkeTransactionCardWrapper = styled.div<{
  background: string;
  mobile_background: string;
}>`
  .transaction-card {
    background: url(${(props) => props.background}) no-repeat;
    box-sizing: content-box;
  }
  .sm-icon {
    display: none;
  }
  .card-btn {
    width: 139px;
    height: 29px;
  }
  @media (max-width: 991.98px) {
    .transaction-card {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 575.98px) {
    .transaction-card {
      background: url(${(props) => props.mobile_background}) no-repeat;
      box-sizing: border-box;
      padding: 15px 10px;
    }
    .icon-title-amount {
      display: block;
      padding-bottom: 12px;
    }
    .card-amount {
      font-size: 18px;
    }
    .sm-icon {
      display: block;
      padding-bottom: 12px;
    }
    .lg-icon {
      display: none;
    }
    @media (max-width: 375.98px) {
      .transaction-card {
        width: 159px !important;
        margin-right: 5px !important;
      }
    }
  }
`;

interface MonkeTransactionCardProps {
  icon: string;
  mobile_icon: string;
  background: string;
  mobile_background: string;
  title: string;
  amount: number;
  btn_title: string;
  onClick: any;
}

const MonkeTransactionCard: FunctionComponent<MonkeTransactionCardProps> = ({
  icon,
  mobile_icon,
  background,
  mobile_background,
  title,
  amount,
  btn_title,
  onClick,
}) => {
  return (
    <MonkeTransactionCardWrapper
      background={background}
      mobile_background={mobile_background}
    >
      <div className="flex flex-col transaction-card pl20 pt21 pb13 mb20 fill-width mr20 br8">
        <div className="flex pb13 icon-title-amount">
          <img src={icon} alt="icon" className="pr13 lg-icon" />
          <img src={mobile_icon} alt="icon" className="pr13 sm-icon" />
          <div className="">
            <div className="font-12 regular-text secondary--text">{title}</div>
            <div className="font-24 extra-bold-text secondary--text card-amount">
              {amount}
            </div>
          </div>
        </div>
        <div>
          <button
            className="card-btn font-12 white--text secondary br8 border-none cursor-pointer"
            onClick={onClick}
          >
            {btn_title}
          </button>
        </div>
      </div>
    </MonkeTransactionCardWrapper>
  );
};

MonkeTransactionCard.propTypes = {};

MonkeTransactionCard.defaultProps = {};

export default MonkeTransactionCard;
