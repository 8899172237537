import HttpClient from "./index";

class MonkeBankAPI extends HttpClient {

  constructor(token?: any) {
    super(process.env.REACT_APP_APIBASE!, token);
  }

  public async getTokens(token: string) {
    return await this.instance.get(`/wallet/get_tokens?wallet=${token}`);
  }
  
  public async getUserBalance(token: string) {
    return await this.instance.get(`/wallet/get_user_balance/${token}/`);
  }

  public async getStakedTokens(token: string) {
    return await this.instance.get(`/wallet/get_staked_tokens?wallet=${token}`);
  }

  public async getBoostTokens(token: string) {
    return await this.instance.get(`/wallet/get_boost_tokens?wallet=${token}`);
  }

  public async getMetadataGenesis() {
    return await this.instance.get("http://coolmonkes.io/api/metadata/genesis/1");
  }

  public async getMetadataBoost() {
    return await this.instance.get("http://coolmonkes.io/api/metadata/boost/1");
  }

  public async getMetadataTrainer() {
    return await this.instance.get("http://coolmonkes.io/api/metadata/trainer/1");
  }

  public async getActiveWL() {
    return await this.instance.get("/getActiveWL");
  }

  public async getWithdrawalValidate(token: string){
    return await this.instance.get(`https://coolmonkes.io/api/validateCMB/${token}/0`);
  }

  public async getStakeValidate(token: string, count: Number){
    return await this.instance.get(`https://coolmonkes.io/api/validateStake/${token}/${count}`);
  }
}

export default MonkeBankAPI;