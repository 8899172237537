import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import DashboardRoutes from "routes/Dashboard";
import Sidebar from "components/mainlayout/Sidebar";
import TopHeader from "components/common/TopHeader";
import { useDispatch } from "react-redux";
import { getBoostTokens, getStakedTokens, getTokens, getUserBalance } from "redux/actions/monkeBank";

const MainLayoutWrapper = styled.div<{ isOpen: boolean }>`
  .lg-flex {
    display: flex;
    color: #fff;
  }
  .main-dashboard {
    color: #fff;
    background-color: #1c2134;
    width: calc(100vw - ${(props) => (props.isOpen ? "290px" : "122px")});
  }
  @media (max-width: 991.98px) {
    .lg-flex {
      flex-direction: column;
    }
    .main-dashboard {
      width: 100%;
      min-height: calc(100vh - 51px);
    }
  }
`;

interface MainLayoutProps {}

const MainLayout: FunctionComponent<MainLayoutProps> = () => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserBalance());
    dispatch(getStakedTokens());
    dispatch(getTokens());
    dispatch(getBoostTokens());
  }, [dispatch]);
  
  return (
    <MainLayoutWrapper isOpen={isOpen}>
      <div className="lg-flex">
        <div className="sticky-sidebar">
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="flex-1 main-dashboard">
          <TopHeader/>
          <DashboardRoutes />
        </div>
      </div>
    </MainLayoutWrapper>
  );
};

MainLayout.propTypes = {};

MainLayout.defaultProps = {};

export default MainLayout;
