import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appToken } from "redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { getURLToken } from "redux/selectors/auth";
import {
  connectWalletIcon,
  discordIcon,
  menuIcon,
  openSeaIcon,
  sidebar_logo,
  twitterIcon,
} from "helper/constants";
import useDevice from "hooks/useDevice";

const HeaderWrapper = styled.div`
  justify-content: space-between;
  .header {
    max-width: 1400px;
    margin: 0 auto;
  }
  .header_dropdown {
    .ant-space {
      font-size: 18px;
    }
  }
  .header_imgs {
    width: 48px;
    height: 48px;
  }
  .cw_btn {
    background: #fbc937;
    color: #343a55;
    box-shadow: 0px 2px 17px rgba(64, 165, 197, 0.75);
    border-radius: 8px;
    width: 200px;
    height: 48px;
    border: none;
  }

  @media (max-width: 575px) {
    .cw_btn {
      width: 120px;
    }
  }
`;

const guideMenu = (
  <Menu
    items={[
      {
        label: <div>Guide 1</div>,
        key: "0",
      },
    ]}
  />
);

const moreMenu = (
  <Menu
    items={[
      {
        label: <div>More 1</div>,
        key: "0",
      },
    ]}
  />
);

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxStoreToken = useSelector(getURLToken);

  // var token = "";

  const connectWalletToken = () => {
    (window as any).ethereum
    .request({
        method: "eth_requestAccounts",
    })
    .then((accounts : string[]) => {
      var token = accounts[0];
      dispatch(appToken({ token }));
      navigate("/dashboard/monkebank");
    })
    .catch((error: any) => {
        alert(`Something went wrong: ${error}`);
    });
  };

  return (
    <HeaderWrapper>
      {!isMobile ? (
        <div className="flex justify-between items-center white--text header pt50">
          <div className="flex items-center">
            <img src={sidebar_logo} alt="logo" width="48px" height="38px" />
            <div className="ml20">
              <div className="font-20 extra-bold-text">Cool</div>
              <div
                className="font-18 regular-text"
                style={{ marginTop: "-5px" }}
              >
                Monkes
              </div>
            </div>
            {/* <Dropdown
              overlay={guideMenu}
              trigger={["click"]}
              className="header_dropdown cursor-pointer ml80 mr40"
            >
              <div onClick={(e) => e.preventDefault()}>
                <Space>
                  Guides
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown>
            <Dropdown
              overlay={moreMenu}
              trigger={["click"]}
              className="header_dropdown cursor-pointer"
            >
              <div onClick={(e) => e.preventDefault()}>
                <Space>
                  More
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown> */}
          </div>

          <div className="flex">
            <a href="https://twitter.com/coolmonkes"><img src={twitterIcon} alt="twitterIcon" className="header_imgs" /></a>
            <a href="https://discord.gg/coolmonkes">
            <img
              src={discordIcon}
              alt="discordIcon"
              className="header_imgs ml14"
            />
            </a>
            <a href="https://opensea.io/collection/coolmonkes">
            <img
              src={openSeaIcon}
              alt="openSeaIcon"
              className="header_imgs ml14"
            />
            </a>
            {!reduxStoreToken && (
              <button
                className="cw_btn font-14 extra-bold-text flex justify-center items-center ml14 cursor-pointer"
                onClick={connectWalletToken}
              >
                <img
                  src={connectWalletIcon}
                  alt="connectWalletIcon"
                  className="mr10"
                />
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center white--text pt24 px18">
            <div className="flex items-center">
              <img src={sidebar_logo} alt="logo" width="48px" height="38px" />
              <div className="ml10">
                <div className="font-18 extra-bold-text">Cool</div>
                <div
                  className="font-16 regular-text"
                  style={{ marginTop: "-5px" }}
                >
                  Monkes
                </div>
              </div>
            </div>
            {!reduxStoreToken && (
              <Button className="cw_btn font-14 extra-bold-text flex justify-center items-center"  onClick={connectWalletToken}>
                <img
                  src={connectWalletIcon}
                  alt="connectWalletIcon"
                  className="mr10"
                />
                Wallet
              </Button>
            )}
            <img src={menuIcon} alt="menuIcon" />
          </div>
        </div>
      )}
    </HeaderWrapper>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
