import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { boost_button_rocket } from "helper/constants";

const CardButtonWrapper = styled.div`
  .btn {
    width: auto;
    border: 1px solid #fbc937;
  }
  @media (max-width: 991.98px) {
    .rocket-img {
      display: none;
    }
    .btn-text {
      padding: 4px 16px;
    }
  }
`;

interface CardButtonProps {
  action: string;
  onClick?: () => void;
}

export const CardButton: FunctionComponent<CardButtonProps> = ({
  action = "boost",
  onClick
}) => {
  return (
    <CardButtonWrapper>
      <div
        className="btn font-12 flex br8 cursor-pointer transparent"
        onClick={onClick}
      >
        <div className="px10 self-center rocket-img">
          <img src={boost_button_rocket} alt="btn-img" />
        </div>
        <div className="pr9 py7 yellow--text semi-bold-text btn-text">
          {action === "boost" ? "Boost me" : "Upgrade"}
        </div>
      </div>
    </CardButtonWrapper>
  );
};

CardButton.propTypes = {};

CardButton.defaultProps = {};
