import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { arrow_left } from "helper/constants";
import { showElementByPathName } from "helper/methods";

const TopHeaderWrapper = styled.div`
  .back-btn {
    width: 32px;
    height: 32px;
    background: #555d82;
  }
  @media (max-width: 991.98px) {
    .web-nav {
      background: transparent;
      padding-top: 25px;
      padding-bottom: 0px;
    }
  }
`;

interface TopHeaderProps {}

const TopHeader: FunctionComponent<TopHeaderProps> = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const path = decodeURIComponent(location.pathname);

  const headerName =
    path
      .substring(path.lastIndexOf("/") + 1)
      .charAt(0)
      .toUpperCase() + path.substring(path.lastIndexOf("/") + 2);

  const showBackBtn = showElementByPathName(
    ["upgrade%20boosters", "apply%20boosters"],
    location.pathname
  );

  return (
    <div className="sticky-header-resName">
      <TopHeaderWrapper>
        <div className="secondary white--text extra-bold-text font-24 pt18 pb15 pl20 flex web-nav">
          {showBackBtn && (
            <div className="back-btn br4 mr17 cursor-pointer">
              <img src={arrow_left} alt="back" className="pl6" onClick={() => navigate(-1)} />
            </div>
          )}
          {headerName}
        </div>
      </TopHeaderWrapper>
    </div>
  );
};

TopHeader.propTypes = {};

TopHeader.defaultProps = {};

export default TopHeader;
